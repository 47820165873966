// About.js

import React, { useEffect, useRef } from 'react';
import '../Css/Pages/AboutPage.css';
import { Helmet } from 'react-helmet';
import AboutImage from '../assets/InpersonPhotos/Norma.jpg';
import { FaLightbulb, FaUsers, FaRocket, FaHeart } from 'react-icons/fa';

const AboutPage = () => {
  
  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}${window.location.pathname}`;
  const sectionRefs = useRef([]);
  useEffect(() => {
    const nav = document.querySelector('nav');
    const navLinks = document.querySelectorAll('nav .nav-links a');
    const dropdownButtons = document.querySelectorAll('nav .dropdown button');

  
    const setNavColor = (color) => {
      navLinks.forEach(link => link.style.color = color);
      dropdownButtons.forEach(button => button.style.color = color);
    };

    const handleScroll = () => {
      if (window.scrollY > 50) {
        nav.classList.add('scrolled');
        setNavColor('#333');
      } else {
        nav.classList.remove('scrolled');
        setNavColor('#ffffff');
      }
    };

    setNavColor('#ffffff');
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      setNavColor('');
    };
  })
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionRefs.current.forEach((ref) => observer.observe(ref));

    return () => observer.disconnect();
  }, []);


  return (
    <div>
        <Helmet>
        <title>About SmeadIT</title>
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" hreflang="en-au" href="https://smeadit.au/about" />
        <link rel="alternate" hreflang="en" href="https://smeadit.com/about" />

        <meta
          name="description"
          content="Learn about SmeadIT, Darcy's journey, and how we help individuals, seniors, and businesses in Turramurra navigate technology with ease."
        />
        <meta property="og:title" content="About SmeadIT -Turramurra assistance in Technology" />
        <meta property="og:description" content="Learn about SmeadIT, Darcy's journey, and how we help individuals, seniors, and businesses in Turramurra navigate technology with ease." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="../assets/Smeadit.png" />
      </Helmet>


  <section className="smeadit-hero">
    <div className="smeadit-hero-content">
      <h1>About SmeadIT</h1>
      <p>Helping Turramurra’s community with technology they can trust</p>
    </div>
  </section>

  <div className="smeadit-container">
    <section
      ref={(el) => (sectionRefs.current[0] = el)}
      className="smeadit-section smeadit-about-section"
    >
      <h2>Our Journey</h2>
      <div className="smeadit-about-detail">
        <p>
          SmeadIT was born out of Darcy's love for technology and his desire to
          make it simple for everyone in the community. Starting out in
          Turramurra, Darcy and the team have helped countless seniors,
          businesses, and everyday people feel more confident with their
          devices. From showing a grandparent how to join a Zoom call with
          family to helping small businesses set up secure networks, our focus
          has always been on making tech friendly and approachable.
        </p>
        <img src={AboutImage} alt="Darcy from SmeadIT helping the community" />
      </div>
    </section>

    <section ref={(el) => (sectionRefs.current[1] = el)} className="smeadit-section">
      <div className="smeadit-subsection">
        <h3>
          <FaLightbulb /> Our Mission
        </h3>
        <p>
          At SmeadIT, we believe technology should be something you’re
          comfortable with, no matter your age or level of experience. Our goal
          is simple – to help you feel confident and in control of your digital
          world. Whether you're a senior needing help with your first smartphone
          or a business owner looking to streamline operations, we’re here to
          guide you every step of the way.
        </p>
      </div>
    </section>

    <section ref={(el) => (sectionRefs.current[2] = el)} className="smeadit-section">
      <div className="smeadit-subsection">
        <h3>
          <FaUsers /> How We Help
        </h3>
        <p>
          SmeadIT takes a personal approach to tech support. Darcy and the team
          know how frustrating technology can be, and they’re always patient,
          clear, and ready to help. Whether it's teaching seniors how to email
          their grandchildren, helping individuals keep their devices secure, or
          supporting local businesses with their tech needs, we do it all with
          care. We’ve been a helping hand to many in Turramurra and are proud to
          be part of this wonderful community.
        </p>
      </div>
    </section>

    <section ref={(el) => (sectionRefs.current[3] = el)} className="smeadit-section">
      <div className="smeadit-subsection">
        <h3>
          <FaRocket /> Innovation in Simple Terms
        </h3>
        <p>
          Technology can be confusing, but Darcy’s passion lies in making it
          easy to understand. From the latest apps to keeping your online
          presence safe, SmeadIT stays on top of the newest developments, but we
          always explain things in a way that makes sense. We help you get the
          most out of technology without the jargon.
        </p>
      </div>
    </section>

    <section ref={(el) => (sectionRefs.current[4] = el)} className="smeadit-section">
      <div className="smeadit-subsection">
        <h3>
          <FaHeart /> A Part of the Turramurra Community
        </h3>
        <p>
          Darcy and the SmeadIT team are proud to call Turramurra home. We've
          built strong relationships with local residents and businesses, and we
          love giving back through workshops and free tech advice sessions. It's
          not just about fixing issues – it's about making sure everyone feels
          included in the digital world, one solution at a time.
        </p>
      </div>
    </section>
  </div>
</div>

  );
};

export default AboutPage;
