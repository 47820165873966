import React, { useState } from 'react';
import './PreDashboardPage.css';


const initialFaqs = [
    { question: 'How do I customize my dashboard?', answer: 'You can customize your dashboard by...' },
    { question: 'What data analytics features are available?', answer: 'Our dashboard offers a variety of data analytics features including...' },
    { question: 'Can I integrate other services with the dashboard?', answer: 'Yes, our dashboard allows integration with services such as...' },
    { question: 'How do I set up notifications?', answer: 'To set up notifications, go to the settings panel and...' },
    { question: 'Is there mobile support for the dashboard?', answer: 'Yes, our dashboard is fully responsive and can be accessed on mobile devices.' },
    { question: 'How secure is the data on the dashboard?', answer: 'We prioritize security with multiple layers of protection including...' },
    { question: 'Can I export reports from the dashboard?', answer: 'Yes, you can export reports in various formats by...' },
    { question: 'How do I add or remove widgets?', answer: 'Widgets can be added or removed from the customization menu by...' },
    { question: 'What customer support options are available?', answer: 'We offer several customer support options including live chat, email, and phone support.' },
    { question: 'Are there any collaboration tools?', answer: 'Yes, our dashboard includes collaboration tools like shared dashboards and comment sections.' },
];

const featuredVideo = 'https://www.youtube.com/embed/G1hKzCkywM8';

const articleCategories = [
    // Updated with distinct article names and their respective links
    { category: 'User Guides', articles: [{name: "Article 1 Title", link: 'https://example.com/article1'}, {name: "Article 2 Title", link: 'https://example.com/article2'}] },
    { category: 'Best Practices', articles: [{name: "Article 3 Title", link: 'https://example.com/article3'}, {name: "Article 4 Title", link: 'https://example.com/article4'}] },
    // Add more categories and articles
];

const quickLinks = [
    { name: 'Get Help', link: '/help' },
    { name: 'Setup Payment', link: '/payment' },
    // Add more quick links as needed
];

const faqCategories = [
    { category: 'General', faqs: initialFaqs.slice(0, 5) },
    { category: 'Technical', faqs: initialFaqs.slice(5) },
    // Add more categories and FAQs
];


const PreDashboardPage = () => {
    const [activeVideoCategory, setActiveVideoCategory] = useState(0);
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const [activeArticleCategory, setActiveArticleCategory] = useState(0);
    const [activeFaqCategory, setActiveFaqCategory] = useState(0);
    const [activeFaqIndex, setActiveFaqIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleFaqClick = (index) => {
        setActiveFaqIndex(activeFaqIndex === index ? null : index);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };
    const filteredFaqs = searchTerm
    ? faqCategories.reduce((acc, cat) => acc.concat(cat.faqs.filter(faq => 
        faq.question.toLowerCase().includes(searchTerm) || 
        faq.answer.toLowerCase().includes(searchTerm)
      )), [])
    : faqCategories[activeFaqCategory].faqs;

        return (
            <main className="main-content">
                <div className="pre-dashboard-container">
                    <h1>Welcome to SmeadIT Dashboard</h1>
                    <p>Discover how our dashboard can streamline your workflows and enhance your business operations.</p>
        
                    {/* Featured Tutorial Video */}
                    <div className="tutorial-videos">
                        {/* <h2>Dashboard Overview</h2> */}
                        <iframe
                            src={featuredVideo}
                            frameBorder="0"
                            allowFullScreen
                            title="Featured Video"
                            className="featured-video"
                        ></iframe>
                    </div>
        
                    {/* Articles Section */}
                    <div className="articles">
                        <h2>Helpful Articles</h2>
                        {articleCategories.map((category, index) => (
                            <div key={index} className="article-category">
                                <h3>{category.category}</h3>
                                <div className="category-content">
                                    {category.articles.map((article, idx) => (
                                        <a key={idx} href={article.link} target="_blank" rel="noopener noreferrer">{article.name}</a>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
    
                    {/* Quick Links Section */}
                    <div className="quick-links">
                        <h2>Quick Links</h2>
                        {quickLinks.map((link, index) => (
                            <a key={index} href={link.link} className="quick-link">{link.name}</a>
                        ))}
                    </div>
    
                    <div className="faq-section">
                    <h2>Frequently Asked Questions</h2>
                    <input
                        type="text"
                        className="faq-search"
                        placeholder="Search FAQs..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    {filteredFaqs.length > 0 ? (
                        filteredFaqs.map((faq, index) => (
                            <div key={index} className="faq-item">
                                <button className="faq-question" onClick={() => handleFaqClick(index)}>
                                    {faq.question}
                                </button>
                                {activeFaqIndex === index && <p className="faq-answer">{faq.answer}</p>}
                            </div>
                        ))
                    ) : (
                        <p>No matching FAQs found.</p>
                    )}
                </div>
    
    
                    <button className="explore-button">Explore Dashboard</button>
                </div>
            </main>
        );
    }
    
    export default PreDashboardPage;