import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { firestore } from '../firebase';
import { query, collection, where, getDocs } from 'firebase/firestore';
import '../../Css/QuotePage.css';

const MyRequests = () => {
  const [requests, setRequests] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserLoggedIn(true);
        setUserEmail(user.email);
        fetchRequests(user.email);
      } else {
        setIsUserLoggedIn(false);
        const urlParams = new URLSearchParams(window.location.search);
        const emailFromURL = urlParams.get('email');
        if (emailFromURL) {
          setUserEmail(emailFromURL);
          fetchRequests(emailFromURL);
        } else {
          setUserEmail('');
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchRequests = async (email) => {
    const q = query(collection(firestore, "quoteRequests"), where("email", "==", email));
    try {
      const querySnapshot = await getDocs(q);
      const fetchedRequests = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRequests(fetchedRequests);
    } catch (error) {
      console.error("Error fetching quotes:", error);
    }
  };

  const handleInputChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchRequests(userEmail);
  };


  return (
    <div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="container">
      <h2>My Quote Requests</h2>
      {!isUserLoggedIn && !userEmail && (
        <div className="inputForm">
          <form onSubmit={handleSubmit}>
            <input 
              type="email" 
              name="email" 
              placeholder="Enter your email" 
              value={userEmail} 
              onChange={handleInputChange} 
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
      
        <div className="requestsContainer">
 
        {requests.length > 0 ? (
            <ul className="requestsList">
            {requests.map((request) => (
                <li key={request.id}>
                <p>Name: {request.name}</p>
                <p>Email: {request.email}</p>
                <p>Phone: {request.phone}</p>
                <p>Service Type: {request.serviceType}</p>
                <p>Service Requested: {request.selectedService}</p>
                <p>Additional Information: {request.additionalInfo}</p>
                <p>Booking Date: {new Date(request.bookingDate).toLocaleDateString()}</p>
                <p>Booking Time: {request.bookingTime}</p>
                <p className={request.confirmed ? 'statusConfirmed' : 'statusNotConfirmed'}>
                    Booking Confirmed: {request.confirmed ? 'Yes' : 'No'}
                </p>
                <p className={request.status === 'Pending' ? 'statusPending' : 'statusCompleted'}>
                    Status: {request.status || 'Pending'}
                </p>
                <p>Submitted On: {new Date(request.submissionTimestamp).toLocaleString()}</p>
                </li>
            ))}
            </ul>
        ) : (
            <p className="noRequests">No quote requests found.</p>
        )}
        </div>

    </div>
    <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

    </div>
  );
};

export default MyRequests;