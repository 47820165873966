import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore, storage } from '../components/firebase';
import '../Css/Messages.css';
import {
  collection, addDoc, query, where, onSnapshot, serverTimestamp, getDocs, updateDoc, doc
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const ClientChatRoom = () => {
  const [user] = useAuthState(auth);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [adminOnline, setAdminOnline] = useState(false);

 const getOrCreateChatRoom = async () => {
  if (!user) return null;

  try {
    const chatRoomsRef = collection(firestore, 'chatrooms');
    // Check for chat rooms where the client is a participant
    const q = query(chatRoomsRef, where('participants', 'array-contains', user.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Use the first chat room where the user is a participant
      return querySnapshot.docs[0].id;
    } else {
      // Create a new chat room if none exists
      const chatRoomRef = await addDoc(chatRoomsRef, {
        createdBy: user.uid,
        createdAt: serverTimestamp(),
        participants: [user.uid],
      });
      return chatRoomRef.id;
    }
  } catch (error) {
    console.error("Error in getOrCreateChatRoom: ", error);
    return null;
  }
};

  window.onload = function() {
    const messagesContainer = document.querySelector('.messages-container');
    if (messagesContainer) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
};


  useEffect(() => {
    if (user && !chatRoomId) {
      getOrCreateChatRoom().then(id => setChatRoomId(id));
    }
  }, [user]);

  useEffect(() => {
    // Request notification permission
    if ('Notification' in window) {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (chatRoomId) {
      const messagesRef = collection(firestore, 'chatrooms', chatRoomId, 'messages');
      const unsubscribe = onSnapshot(messagesRef, (querySnapshot) => {
        const msgs = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.createdAt - b.createdAt); // Sort messages
        setMessages(msgs);
        // checkIfAdminResponded(msgs);
        
        // Check for new messages and show notification
        if (msgs.length > 0 && msgs[msgs.length - 1].uid !== user.uid) {
          // showNotification(msgs[msgs.length - 1].text);
        }
      });
      return () => unsubscribe; 
    }
 }, [user, chatRoomId]);

  // Function to show notification
  // const showNotification = (message) => {
  //   if ('Notification' in window && Notification.permission === "granted") {
  //     new Notification("New Message", {
  //       body: message,
  //       // icon: "/path-to-your-icon.png" 
  //     });
  //   }
  // };


  const sendMessage = async (e) => {
    e.preventDefault();
  
    // Check if the message is empty or only contains whitespace
    if (!message.trim()) {
      console.error("Cannot send an empty message.");
      return;
    }
  
    try {
      const roomId = chatRoomId || await getOrCreateChatRoom();
      if (!roomId) return;
  
      let fileUrl = null;
      if (file) {
        const fileRef = ref(storage, `files/${file.name}`);
        const snapshot = await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(snapshot.ref);
      }
  
      // Add the message to the 'messages' collection
      const messageRef = await addDoc(collection(firestore, 'chatrooms', roomId, 'messages'), {
        uid: user.uid,
        text: message,
        fileUrl,
        createdAt: serverTimestamp()
      });
  
      // Prepare the notification details
      const notificationDetails = {
        eventType: 'New Message',
        messageText: message,
        senderId: user.uid,
        timestamp: serverTimestamp(),
        chatRoomId: roomId,
      };
  
      // Add the notification to 'adminNotifications' collection
      await addDoc(collection(firestore, 'adminNotifications'), notificationDetails);
  
      setFile(null);
      setMessage('');
      if (!chatRoomId) setChatRoomId(roomId);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  // const checkIfAdminResponded = (messages) => {
  //   const adminResponded = messages.some(msg => msg.uid === "ue9es4BQnpVA4X9Ig4gd01MsdZv2"); // Replace "adminUID" with actual admin UID
  //   setAdminOnline(adminResponded);
  // };

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
  };

  const isAdminMessage = (uid) => {
    return user && uid !== user.uid; // Change to check if the UID is not the logged-in user's UID
  };

  return (
    <main className="main-content">
      <div className="chat-container">
        <div className="messages-container">
          {messages.map((msg) => (
            <div 
              key={msg.id} 
              className={`message ${isAdminMessage(msg.uid) ? 'message-admin' : 'message-user'}`}
            >
              {isAdminMessage(msg.uid) && <div className="message-admin-label">Admin</div>}
              <p className="message-text">{msg.text}</p>
              {msg.fileUrl && (
                <a href={msg.fileUrl} target="_blank" rel="noopener noreferrer" className="message-file">
                  View File
                </a>
              )}
            </div>
          ))}
     
      </div>
      {/* <div className="admin-status"> 
        {adminOnline ? <p>Admin is online</p> : <p>Admin is offline</p>}
      </div> */}
      <form onSubmit={sendMessage} className="form-container">
        <input
          className="input-message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
        />
        <label className="input-file-label">
          <input 
            type="file" 
            onChange={handleFileInput}
            className="input-file"
          />
       
        </label>
        <button type="submit" className="button-send">Send</button>
      </form>

      </div>
    </main>
  );
};

export default ClientChatRoom;
