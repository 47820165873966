import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import '../../Css/Components/ClientViewUpdates.css';

const ClientViewUpdates = () => {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    const updatesQuery = query(collection(firestore, 'updates'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(updatesQuery, (querySnapshot) => {
      const updatesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUpdates(updatesList);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="updates-container">
             <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      
      <br/>
      {updates.map(update => (
        <div key={update.id} className="update">
          <h2>{update.title} - Version {update.version}</h2>
          <p className="update-info">{update.info}</p> {/* Use a class to target CSS */}
          <small>Posted on: {update.createdAt?.toDate().toLocaleString()}</small>
        </div>
      ))}

           <br/>
      <br/>
      <br/>
    </div>
  );
};

export default ClientViewUpdates;
