import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../components/firebase';
import '../Css/Dashboard.css';
import Sidenav from '../../components/Navs/SideNav';
import PluginStore from '../PluginStore';
import UserSettings from '../UserSettings'; 

const defaultWidgets = {
  
  'My Tech Requests': { description: 'See your support requests and the status of them.', enabled: true, url: '/myrequests' },
  'Book Tech Support': { description: 'Connect with our support team instantly.', enabled: true, url: '/booksupport' },
  'Payment and Plan': { description: 'Get in touch with our payment and plan options.', enabled: true, url: '/paymentmethods' },
  'Device Management': { description: 'Manage your connected devices.', enabled: true, url: '/devicemanagement' },
  'SmeadIT Chatbot': { description: 'Try our new online AI assistant to help you with your tech needs.', enabled: true, url: '/ai' },
  'Support Guides': { description: 'Find helpful resources and guides.', enabled: true, url: '/support' },
  'Message Support': { description: 'Connect with our support team instantly.', enabled: true, url: '/messages' },
  'Read Blog Posts': { description: 'Connect with our support team instantly.', enabled: true, url: '/blogs' },
  
  'Contact SmeadIT': { description: 'Need further assistance? Get in touch.', enabled: true, url: '/contact' },
  
  'Edit Profile': { description: 'Edit your profile and add a profile pic and username.', enabled: true, url: '/profile' },

  // 'Plugin Store': { description: 'Browse our plugin store and install plugins.', enabled: true, url: '/plugin-store' },
  // 'Call a Tech Assistant': { description: 'Connect with our support team instantly with a video call.', enabled: true, url: '/clientcall' },
  // 'Booking': { description: 'Schedule your support session with us.', enabled: true, url: '/booking' },
   
  // 'Tech Support Tutorials': { description: 'Video guides for common tech issues.', enabled: true, url: '/tech-tutorials' },
};

const extendedDefaultWidgets = {
  ...defaultWidgets,
   
    'Updates': { description: 'Check out our latest updates and features.', enabled: true, url: '/updates' },
   'Invoices': { description: 'Manage your transactions with ease.', enabled: false, url: '/payments' },
   'Tech Logs': { description: 'Access technical logs and reports.', enabled: false, url: '/tech-logs' },
   'Service History': { description: 'Review your past service records.', enabled: false, url: '/service-history' },

};
const Dashboard = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [dashboardWidgets, setDashboardWidgets] = useState(extendedDefaultWidgets);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userFullName, setUserFullName] = useState('');
  const [dashboardAlerts, setDashboardAlerts] = useState([]);
  const [userNotifications, setUserNotifications] = useState([]);
  const [isPluginStoreVisible, setIsPluginStoreVisible] = useState(false);
  const [widgetOrder, setWidgetOrder] = useState(Object.keys(extendedDefaultWidgets)); // Initialize widgetOrder based on the extendedDefaultWidgets

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
          if (currentUser) {
              setUser(currentUser);
              loadUserWidgets(currentUser.uid);
              fetchUserFullName(currentUser.uid);
              checkLogoutStatus(currentUser.uid);
              fetchUserNotifications(currentUser.uid);
          } else {
              navigate('/login');
          }
      });

      return () => {
          unsubscribe();
      };
  }, [auth, navigate]);

  const checkLogoutStatus = async (userId) => {
    try {
      const userRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists() && userDoc.data().isLoggedOut) {
        await signOut(auth); // Log the user out
        navigate('/login');
      }
    } catch (error) {
      console.error('Error checking logout status:', error);
    }
  };

  
  const fetchUserFullName = async (userId) => {
      const userRef = doc(firestore, 'users', userId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
          setUserFullName(userSnap.data().fullName);
      }
  };

  const loadUserWidgets = (userId) => {
      const savedData = localStorage.getItem(`dashboardWidgetsV2_${userId}`);
      if (savedData) {
          const { widgets, order } = JSON.parse(savedData);
          setDashboardWidgets(widgets);
          setWidgetOrder(order);
      } else {
          setDashboardWidgets(defaultWidgets);
          setWidgetOrder(Object.keys(defaultWidgets));
      }
  };

  const saveWidgetsToCache = (userId) => {
      const dataToSave = {
          widgets: dashboardWidgets,
          order: widgetOrder,
      };
      localStorage.setItem(`dashboardWidgetsV2_${userId}`, JSON.stringify(dataToSave));
  };

  const toggleEditMode = () => {
      setIsEditMode(!isEditMode);
      setIsPluginStoreVisible(!isPluginStoreVisible);
      if (isEditMode) {
          saveWidgetsToCache(user.uid);
      }
  };

  const addWidget = (widgetName) => {
      const updatedWidgets = { ...dashboardWidgets, [widgetName]: { ...extendedDefaultWidgets[widgetName], enabled: true } };
      setDashboardWidgets(updatedWidgets);
      if (!widgetOrder.includes(widgetName)) {
          setWidgetOrder([...widgetOrder, widgetName]);
      }
  };

  const removeWidget = (widgetName) => {
      setDashboardWidgets((prevWidgets) => ({ ...prevWidgets, [widgetName]: { ...prevWidgets[widgetName], enabled: false } }));
      setWidgetOrder((prevOrder) => prevOrder.filter((name) => name !== widgetName));
  };

  const moveWidgetUp = (widgetName) => {
      const index = widgetOrder.indexOf(widgetName);
      if (index > 0) {
          const newWidgetOrder = [...widgetOrder];
          [newWidgetOrder[index - 1], newWidgetOrder[index]] = [newWidgetOrder[index], newWidgetOrder[index - 1]];
          setWidgetOrder(newWidgetOrder);
      }
  };

  const moveWidgetDown = (widgetName) => {
      const index = widgetOrder.indexOf(widgetName);
      if (index < widgetOrder.length - 1) {
          const newWidgetOrder = [...widgetOrder];
          [newWidgetOrder[index], newWidgetOrder[index + 1]] = [newWidgetOrder[index + 1], newWidgetOrder[index]];
          setWidgetOrder(newWidgetOrder);
      }
  };







const fetchUserNotifications = async (userId) => {
  try {
    const notificationsQuery = query(
      collection(firestore, "notifications"),
      where("userId", "==", userId),
      where("read", "==", false) // Fetch only unread notifications
    );
    const querySnapshot = await getDocs(notificationsQuery);
    const fetchedNotifications = querySnapshot.docs.map(doc => ({
      id: doc.id, // Include the document ID
      ...doc.data()
    }));
    setUserNotifications(fetchedNotifications);
  } catch (error) {
    console.error("Error fetching notifications:", error);
  }
};

const handleNotificationClick = async (notificationId) => {
  // Check if notificationId is valid
  if (!notificationId) return;

  // Update the notification's 'read' status in Firestore
  const notificationRef = doc(firestore, "notifications", notificationId);
  await updateDoc(notificationRef, { read: true });


  fetchUserNotifications(user.uid);

  navigate("/messages"); 
};

  


  const clearWidgetsCache = userId => {
    localStorage.removeItem(`dashboardWidgetsV2_${userId}`);
  };
  

  //clearWidgetsCache(user.uid);

   


  const renderWidget = widgetName => {
    const widget = dashboardWidgets[widgetName];
    if (!widget || !widget.enabled) return null;

    return (
      <div className="grid-item" key={widgetName}>
        <h2>{widgetName}</h2>
        <p>{widget.description}</p>
        <Link to={widget.url}>
          <button className="widget-link-btn">Go to {widgetName}</button>
        </Link>
        {isEditMode && (
          <>
            <button className="move-widget-btn" onClick={() => moveWidgetUp(widgetName)}>↑</button>
            <button className="move-widget-btn" onClick={() => moveWidgetDown(widgetName)}>↓</button>
            <button className="remove-widget-btn" onClick={() => removeWidget(widgetName)}>Remove</button>
          </>
        )}
      </div>
    );
  };


  // const TopAlert = () => {
  //   return (
  //     <div className="top-alert">
  //       <p>The call feature is no longer available, as it was not widely used by our users. We apologize for any inconvenience this may cause.</p>
  //     </div>
  //   );
  // };
  
    const InformationSection = () => {
      // Personalized welcome message
      const welcomeMessage = userFullName ? `Welcome back, ${userFullName}!` : "Welcome!";
      return (
        <div className="information-section">
          <h2>{welcomeMessage}</h2>
        <p>Welcome to your user Dashboard! <br/> This is where you can find all the resources you need to succeed! We are committed to providing you with the best possible service, so check out the options below and customize your dashboard to suit your needs.</p>
        {/* Display dashboard alerts */}
        <div className="dashboard-alerts-container">
          {dashboardAlerts.map((alert, index) => (
            <div key={index} className="dashboard-alert">{alert.message}</div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`dashboard ${isPluginStoreVisible ? 'plugin-store-visible' : ''}`}>
      <main className="main-content">
        {/* <TopAlert /> */}
        <InformationSection />

        <div className="dashboard-sections">
        <div className="AlertsContainer">
          <h3>Alerts</h3>
          {userNotifications.length > 0 ? (
            userNotifications.map((notification, index) => (
              <div 
                key={index} 
                className="notification-item"
                onClick={() => handleNotificationClick(notification.id)} // Add click handler
              >
                <h2>New Message Reply</h2>
                {notification.messageText}
                {/* {notification.timestamp} */}
              </div>
            ))
          ) : (
            <p>No new alerts.</p>
          )}
        </div>



  <div className="QuickServicesContainer">
    <center><h3>Quick Links</h3></center>
    <br/>
    <div className='linksContainer'>
    <Link to="/messages">Get Tech Support</Link>
    <Link to="/quote">Book Online Tech Support</Link>
    <Link to="/profile">Learn To Get TeamVeiwer</Link>
    <Link to="/profile">Watch Tutorials</Link>
    <Link to="/profile">Get Help</Link>
    <Link to="/profile">Payment Plans</Link>
    <Link to="/profile">Report a Problem</Link>
    </div>

  </div>
</div>


        <div className="dashboard-grid">
          {widgetOrder.map(renderWidget)}
        </div>
        {isEditMode && (
      <PluginStore
        onAddWidget={addWidget}
        onRemoveWidget={removeWidget}
        activeWidgets={widgetOrder.filter(widget => dashboardWidgets[widget]?.enabled)}
        availableWidgets={extendedDefaultWidgets}
        toggleEditMode={toggleEditMode} // Pass toggleEditMode to PluginStore
      />
    )}
        <br/>
        <br/>
        <br/>
        <br/>
        
        <button className="edit-dashboard-btn" onClick={toggleEditMode}>
          {isEditMode ? 'Save Changes' : 'Edit Dashboard'}
        </button>


      </main>
    </div>
  );
};

export default Dashboard;