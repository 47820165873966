import React, { useState, useEffect } from 'react'; // Correctly import useEffect here
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../components/firebase';
import { doc, updateDoc, collection, addDoc, getDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';


const CheckoutForm = ({ planProp, email, userUid, amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userData, setUserData] = useState({ name: '', email: '' });

  useEffect(() => {

    const fetchUserData = async () => {
      const userRef = doc(firestore, 'users', userUid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setUserData(userSnap.data());
      } else {
        console.error('User not found');
      }
    };

    fetchUserData();
  }, [userUid]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    if (!stripe || !elements) {
      console.error('Stripe has not loaded');
      setErrorMessage('Payment service is not available');
      setLoading(false);
      return;
    }
  
    const corePlanName = planProp.includes(' Plan') ? planProp.replace(' Plan', '') : planProp;
  
    if (!['Essential', 'Professional', 'Enterprise'].includes(corePlanName)) {
      console.error('Invalid plan selected:', corePlanName);
      setErrorMessage('Invalid plan selected');
      setLoading(false);
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    try {
      const { paymentMethod, error: paymentMethodErr } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
  
      if (paymentMethodErr) {
        console.error('Payment method error:', paymentMethodErr);
        setErrorMessage(paymentMethodErr.message);
        setLoading(false);
        return;
      }
  
      const response = await fetch('http://smeadit/create-subscription', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, plan: corePlanName, paymentMethodId: paymentMethod.id }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data.clientSecret) {
        const confirmResult = await stripe.confirmCardPayment(data.clientSecret);
  
        if (confirmResult.error) {
          console.error('Payment failed:', confirmResult.error.message);
          setErrorMessage(confirmResult.error.message);
          setLoading(false);
        } else if (confirmResult.paymentIntent && confirmResult.paymentIntent.status === 'succeeded') {
          console.log('Payment successful!');
  
          await updateDoc(doc(firestore, 'users', userUid), { plan: corePlanName, status: 'Premium' });
  
          await addDoc(collection(firestore, "transactions"), {
            uid: userUid,
            email: email,
            name: userData.name || email,
            plan: corePlanName,
            amount: amount,
            status: 'succeeded',
            timestamp: new Date()
          });
  
          const emailParams = {
            to_email: userData.email,
            to_name: userData.name || email,
            message: `Your subscription to the ${corePlanName} plan is now active.`,
          };
  
          try {
            const emailResult = await emailjs.send('service_oxapmpa', 'template_bn253ra', emailParams, 'MgxbRIVaPaKQYzytG');
            console.log('Email sent:', emailResult.text);
          } catch (emailError) {
            console.error('Email sending error:', emailError);
          }
  
          setLoading(false);  // Stop loading when payment is successful
          setSuccessMessage('Payment successful! Redirecting...');
          setTimeout(() => {
            navigate('/dashboard');
          }, 3000);
        } else {
          throw new Error('Payment failed');
        }
      } else {
        throw new Error('No client secret received');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message || 'An error occurred during payment');
      setLoading(false);
    }
  };
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        // Add your base input styles here
      },
      invalid: {
        // Add styles for invalid state here
      },
    },
    hidePostalCode: true, // Hides the default postal code input
  };

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>
      <p>Plan Selected: <strong>{planProp}</strong></p>
      <p>Total Amount: <strong>${amount}/month</strong></p>
      <p>Email: <strong>{email}</strong></p>
      <form onSubmit={handleSubmit} className="checkout-form">
        <div className="form-group">
          <label className="label">Card Information</label>
          <CardElement className="card-element" options={CARD_ELEMENT_OPTIONS} />
        </div>
        <button type="submit" disabled={!stripe || loading} className="submit-btn">
          {loading ? 'Processing...' : `Pay $${amount}`}
        </button>
      </form>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default CheckoutForm;
