import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../Css/Components/HelpPopup.css'; // Ensure this CSS file is properly linked

const HelpPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const helpSteps = [
    {
      title: 'Welcome to SmeadIT!',
      content: 'Would you like to get in contact with support?.',
      linkText: 'please click here!',
      linkPath: '/contact'
    },
    {
      title: 'Explore Our Features',
      content: 'Discover what you can do with our website.',
      linkText: 'View features',
      linkPath: '/features'
    },
    {
      title: 'Need Further Assistance?',
      content: 'Our support team is here to help you.',
      linkText: 'Contact support',
      linkPath: '/contact'
    }

  ];

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsOpen(false);
  //   }, 5000); 
  //   return () => clearTimeout(timer);
  // }, []);

 
  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep + 1) % helpSteps.length);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => (prevStep - 1 + helpSteps.length) % helpSteps.length);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button className={`help-btn ${isOpen ? 'hide' : ''}`} onClick={togglePopup}>?</button>
      <div className={`help-popup ${isOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={togglePopup}>
          &times;
        </button>
        <h4>{helpSteps[currentStep].title}</h4>
        <p>{helpSteps[currentStep].content}</p>
        <Link to={helpSteps[currentStep].linkPath} className="help-link">
          {helpSteps[currentStep].linkText}
        </Link>
        <div className="slider-controls">
          <button onClick={prevStep}>&laquo; Prev</button>
          <button onClick={nextStep}>Next &raquo;</button>
        </div>
      </div>
    </div>
  );
};

export default HelpPopup;

