import React, { useState, useEffect } from 'react';
import { FaDesktop, FaWifi, FaMobile, FaPrint, FaQuestionCircle, FaRecycle, FaShieldAlt, FaUserGraduate, FaTabletAlt, FaShoppingCart, FaToolbox, FaHome, FaScrewdriver, FaBug, FaHandsHelping, FaTv } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import '../../Css/Components/Model.css';

import emailjs from 'emailjs-com';
import { firestore } from '../firebase'; // Import your Firestore config
import { collection, addDoc, Timestamp } from 'firebase/firestore'; // Correctly import Firestore functions

const servicesList = [
  { icon: <FaDesktop />, title: 'PC Setup & Support', description: 'Assisting with setup, software installation, and general PC troubleshooting.' },
  { icon: <FaDesktop />, title: 'Desktop Setup & Support', description: 'Assisting with setup, software installation, and general PC troubleshooting.' },
  { icon: <FaDesktop />, title: 'Wifi', description: 'Assisting with setup, software installation, and general PC troubleshooting.' },
  // Add other services here...
];

function Modal({ show, onClose }) {
  const [page, setPage] = useState(1);
  const [supportType, setSupportType] = useState('');
  const [suggestedServices, setSuggestedServices] = useState([]);
  const [userDetails, setUserDetails] = useState({ name: '', email: '', phone: '' });
  const [confirmationMessage, setConfirmationMessage] = useState('');

  useEffect(() => {
    const debouncedFilterServices = debounce(() => {
      const lowerCaseSupportType = supportType.toLowerCase();
      const filteredServices = servicesList.filter(service =>
        service.title.toLowerCase().includes(lowerCaseSupportType)
      );
      setSuggestedServices(filteredServices.length > 0 ? filteredServices : [{ title: "Request New Service", description: "Can't find what you're looking for? Request a new service." }]);
    }, 300);

    debouncedFilterServices();

    return () => debouncedFilterServices.cancel();
  }, [supportType]);

  if (!show) return null;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const supportRequest = {
      name: userDetails.name,
      email: userDetails.email,
      phone: userDetails.phone,
      support: userDetails.support,
      supportType: supportType,
      requestedAt: Timestamp.fromDate(new Date()),
    };

    try {
      // Add document to Firestore
      await addDoc(collection(firestore, 'supportRequests'), supportRequest);

      // Send email using EmailJS
      emailjs.send('service_oxapmpa', 'template_bn253ra', supportRequest, 'MgxbRIVaPaKQYzytG')
        .then((response) => {
          console.log('Email sent!', response.status, response.text);
        }, (error) => {
          console.log('Failed to send email', error);
        });

      setConfirmationMessage(`Thank you, ${userDetails.name}. We'll be in touch soon.`);
    } catch (error) {
      console.error("Error adding document: ", error);
    }

    onClose();
  };

  const handleUserDetailsChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const renderServiceItem = (service, index) => (
    <li key={index} className="modal-item">
      <span className="modal-icon">{service.icon}</span>
      <h3>{service.title}</h3>
      <p>{service.description}</p>
      {service.title === "Request New Service" ? (
        <button onClick={() => setPage(4)}>Request</button>
      ) : (
        <button>Learn More</button>
      )}
    </li>
  );


  const nextPage = () => setPage(Math.min(page + 1, 5));
  const previousPage = () => setPage(Math.max(page - 1, 1));



  const submitForm = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    setConfirmationMessage(`Thank you, ${userDetails.name}. We will contact you soon at ${userDetails.email}!`);
    onClose();
  };

  return (
    <div className="modals-overlay">
      <div id="modals">
        {page === 1 && (
          <>
            <h2>Welcome to SmeadIt</h2>
            <video width="320" height="240" controls>
              <source href="https://www.youtube.com/watch?v=GyllRd2E6fg" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p>Watch our short video to learn a bit about smeadit, or explore our services, however if you do not need any help, close this popup</p>
            <button onClick={nextPage}>Request a Demo</button>
            <button onClick={onClose}>Explore Website</button>
          </>
        )}

        {/* {page === 2 && (
          <>
            <form>
              <label htmlFor="supportType">What kind of tech support do you need?</label>
              <input type="text" id="supportType" name="supportType" value={supportType} onChange={(e) => setSupportType(e.target.value)} />
              <button type="button" onClick={nextPage}>Find Services</button>
            </form>
            <button onClick={previousPage}>Back</button>
          </>
        )} */}

      {/* {page === 3 && (
          <>
            <h2>Suggested Services</h2>
            <ul className="modal-list">
              {suggestedServices.map(renderServiceItem)}
            </ul>
            <button onClick={() => setPage(4)}>Request Support</button>
            <button onClick={() => setPage(2)}>Back</button>
          </>
        )} */}

        {page === 2 && (
          <>
            <form onSubmit={handleFormSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" value={userDetails.name} onChange={handleUserDetailsChange} required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" value={userDetails.email} onChange={handleUserDetailsChange} required />

              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" value={userDetails.phone} onChange={handleUserDetailsChange} required />

              <label htmlFor="name">Support Type:</label>
              <input type="text" id="support" name="support" value={userDetails.support} onChange={handleUserDetailsChange} required />

              <button type="submit">Send Request</button>
            </form>
            <button onClick={() => setPage(1)}>Back</button>
          </>
        )}

        {/* {page === 3 && (
          <>
            <p>Review your request:</p>
            <ul>
              <li>Name: {userDetails.name}</li>
              <li>Email: {userDetails.email}</li>
              <li>Phone: {userDetails.phone}</li>
            </ul>
            <button onClick={submitForm}>Confirm & Send</button>
            <button onClick={previousPage}>Back</button>
          </>
        )} */}

    {confirmationMessage && <p className="confirmation-message">{confirmationMessage}</p>}
      </div>
    </div>
  );
}

export default Modal;