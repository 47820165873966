const PluginStore = ({ onAddWidget, onRemoveWidget, activeWidgets, availableWidgets, toggleEditMode }) => {
  if (!availableWidgets) return null; // Safeguard against undefined availableWidgets

  return (
    <div className="plugin-store-popup">
      <h3 className="plugin-store-title">Manage Widgets</h3>
      <ul className="available-widgets-list">
        {Object.keys(availableWidgets).map(widget => (
          <li key={widget} className="widget-list-item">
            <span className="widget-name">{widget}</span>
            {activeWidgets.includes(widget) ? (
              <button className="remove-widget-btn" onClick={() => onRemoveWidget(widget)}>Remove</button>
            ) : (
              <button className="add-widget-btn" onClick={() => onAddWidget(widget)}>Add</button>
            )}
          </li>
        ))}
      </ul>
      <br/>
      <br/>
      <button className="edit-dashboard-btn" onClick={toggleEditMode}>
        Save Changes
      </button>
    </div>
  );
};
export default PluginStore;