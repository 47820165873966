import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaComments } from 'react-icons/fa'; // Import a suitable icon for messaging
import '../../Css/Components/MessageButton.css'
const MessageButton = () => {
  const navigate = useNavigate(); // Allows navigation

  const handleClick = () => {
    navigate("/contact"); // Navigates directly to the messages page
  };

  return (
    <button className="msg-btn" onClick={handleClick}>
      <FaComments /> {/* Adds the messaging icon */}
    </button>
  );
};

export default MessageButton;
