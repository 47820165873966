import React, { useState } from 'react';
import { FaLightbulb, FaUserFriends, FaBriefcase, FaSignInAlt, FaRegIdCard, FaMedal } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { FaRegListAlt, FaCalendarAlt, FaUserCog, FaDollarSign, FaUserPlus  } from 'react-icons/fa';
import PricingTable from '../../components/PricingTable.js';


const GetStartedPage = () => {
  const [currentStep, setCurrentStep] = useState('initial');
  const [planType, setPlanType] = useState('');
  const navigate = useNavigate();
  // Handle the initial choice between quick service and monthly membership
  const handleInitialChoice = (choice) => {
    setCurrentStep(choice);
  };


  const handleMembershipChoice = (choice) => {
    setPlanType(choice);
    setCurrentStep('selectPlan');
  };

  // Handle the selection of Bronze, Silver, or Gold plans
  const handlePlanSelection = (plan) => {
    setCurrentStep(`${planType}_${plan}`);
  };

  
  const renderQuickServiceOptions = () => {
    return (
      <div className="info-bubbles">
        <div className="info-bubble">
          <FaDollarSign className="bubble-icon" />
          <h3>Pay As You Go</h3>
          <p>Opt for immediate, one-time support with a simple payment process. Ideal for quick and specific needs.</p>
          <button className="selectionButton" onClick={() => setCurrentStep('pricings')}>Proceed</button>
        </div>
        <div className="info-bubble">
          <FaUserPlus className="bubble-icon" />
          <h3>Setup Membership</h3>
          <p>Create or access your membership for streamlined services and exclusive benefits.</p>
          <button className="selectionButton" onClick={() => setCurrentStep('monthlyMembership')}>Proceed</button>
        </div>
      </div>
    );
  };


  const handleInitialChoiceButton = () => {
   
      // Open a new tab with the specified URL
      window.open('https://billing.stripe.com/p/login/8wM8yQ0rB5U16LC7ss', '_blank');
    

  };
  

  const renderInitialOptions = () => {
    return (
      <div className="info-bubbles">
        <div className="info-bubble">
          <FaRegListAlt className="bubble-icon" />
          <h3>View Past Payments</h3>
          <p>Access your payment history and review past transactions.</p>
          <button className="selectionButton" onClick={() => handleInitialChoice('pastpayments')}>Select</button>
        </div>
        <div className="info-bubble">
          <FaCalendarAlt className="bubble-icon" />
          <h3>Setup Payment Plan</h3>
          <p>Flexible payment plans to suit your budget and schedule.</p>
          <button className="selectionButton" onClick={() => handleInitialChoice('quickService')}>Select</button>
        </div>
        <div className="info-bubble">
          <FaUserCog className="bubble-icon" />
          <h3>Manage Membership</h3>
          <p>Customize and control your membership details.</p>
          <button className="selectionButton" onClick={() => handleInitialChoiceButton()}>Select</button>

        </div>
      </div>
    );
};



  
  const renderNextOptions = () => {
    return (
      <div className="info-bubbles">
        <div className="info-bubble">
          <FaLightbulb className="bubble-icon" />
          <h3>Monthly Membership</h3>
          <p>Immediate, one-time support, quote on the spot.</p>
          <button className="selectionButton" onClick={() => handleInitialChoice('quickService')}>Select</button>
        </div>
        <div className="info-bubble">
          <FaUserFriends className="bubble-icon" />
          <h3>Pay As You Go</h3>
          <p>Regular support with added benefits.</p>
          <button className="selectionButton" onClick={() => handleInitialChoice('monthlyMembership')}>Select</button>
        </div>
      </div>
    );
  };






  const renderCurrentStep = () => {
    switch (currentStep) {
      case 'initial':
        return renderInitialOptions();
      case 'quickService':
        return renderQuickServiceOptions();
      case 'monthlyMembership':
            navigate('/pricing');
            break;
    
     case 'pricings':
        navigate('/serviceprices');
        // case 'existingCustomerLogin':
        //   return <Login />;
      default:
        return <p>Coming Soon</p>;
    }
  };

  return (
    <div className='ContainerStarter'>
           <br/>
   
     

      <div className="getStartedSection">
        {/* <h1 id='headingTe'>Set Up Your Tech Support Plan</h1> */}
        <div className="getStartedContainer">
          {renderCurrentStep()}
          {currentStep !== 'initial' && <button className="cta-button" id='Startover' onClick={() => setCurrentStep('initial')}>Start Over</button>}
        </div>
        <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

      </div>

    </div>
  );
};

export default GetStartedPage;