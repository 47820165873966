import React from 'react';
import { useLocation } from 'react-router-dom';
import CheckoutForm from './CheckoutForm';
import './checkout.css'
const CheckoutPage = () => {
  const location = useLocation();
  const { plan, email, userUid, amount } = location.state;

  return (
    <div className="checkout-page">
        <br />
        <br />
            <br />
            <br />
            <br />

    <CheckoutForm 
      planProp={plan} 
      email={email} 
      userUid={userUid} 
      amount={amount} 
    />
    </div>
  );
};

export default CheckoutPage;
