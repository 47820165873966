import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm.js';

import { auth, firestore } from '../components/firebase.js';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
// const stripePromise = loadStripe('pk_live_51OUJ44IGXy7izLCvcomjlsENXScrOujxzbG0gVrXAIWqpLtYugbzxqf7gfVCSBmehTl8BS2WLdCfrFwsrRVgiwdJ007mNr3PgM');


const PricingTable = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [user, setUser] = useState(null);
   

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userDocRef = doc(firestore, 'users', userAuth.uid); // Reference to the user's document in Firestore
                const userDoc = await getDoc(userDocRef);
    
                if (userDoc.exists()) {
                    setUser({ uid: userAuth.uid, email: userDoc.data().email });
                  
                } else {
                    console.log('User data not found in Firestore');
                }
            }
        });
    
        return unsubscribe;
    }, []);
    
    const navigate = useNavigate();

    const selectPlan = (plan, amount) => {
        if (user) {
          navigate('/checkout', { state: { plan, email: user.email, userUid: user.uid, amount } });
        } else {
          console.error("User is not defined");
        }
      };

    return (
   


        <section className="pricing-table-section">
        <h2>Discover Your Ideal Tech Support Plan</h2>
        <div className="pricing-table">
            {/* Essential Plan */}
            <div className="pricing-plan">
                <h3>Essential Plan</h3>
                <h4>Monthly Plan</h4>
                <p>$89 - Perfect for Personal Projects & Small Tasks</p>
                <p>Up to 2 Devices Supported</p>
                <p>Bi-monthly Tech Support Sessions, No Additional Fees</p>
                <ul>
                    <li>Convenient tech support for everyday digital hiccups.</li>
                    <li>Regular monthly check-ins for optimal device performance.</li>
                    <li>Access to email and phone support for rapid assistance.</li>
                    <li>Guidance on computer, smartphone, Wi-Fi, and printer issues.</li>
                    <li>Support with software installations and setup.</li>
                </ul>
                <button onClick={() => selectPlan("Essential", 89)}>Choose Plan</button>
            </div>
    
            {/* Professional Plan */}
            <div className="pricing-plan featured">
                <h3>Professional Plan</h3>
                <h4>Monthly Plan</h4>
                <p>$146 - Ideal for Advanced Users & Small Businesses</p>
                <p>Up to 5 Devices Supported</p>
                <p>4 Monthly Tech Support Sessions, No Additional Fees</p>
                <ul>
                    <li>All-encompassing tech support for diverse needs.</li>
                    <li>Monthly device tune-ups for sustained high performance.</li>
                    <li>Exclusive support line for personalized, swift assistance.</li>
                    <li>Intermediate hardware and software troubleshooting.</li>
                    <li>Priority scheduling for timely service.</li>
                    <li>Network setup and maintenance expertise.</li>
                </ul>
                <button onClick={() => selectPlan("Professional", 146)}>Choose Plan</button>
            </div>
    
            {/* Enterprise Plan */}
            <div className="pricing-plan">
                <h3>Enterprise Plan</h3>
                <h4>Monthly Plan</h4>
                <p>$244 - Supreme Choice for Regular Checkups & Large Businesses</p>
                <p>Support for Unlimited Devices</p>
                <p>Unlimited Tech Support Sessions, Zero Call Out Fee</p>
                <ul>
                    <li>Elite tech support with swift action for urgent matters.</li>
                    <li>Weekly system health checks for utmost security and efficiency.</li>
                    <li>24/7 support readiness for any unexpected tech challenges.</li>
                    <li>Advanced solutions for intricate hardware/software issues.</li>
                    <li>Custom IT consultancy aligning technology with your business strategy.</li>
                    <li>Direct on-site support for comprehensive hands-on assistance.</li>
                    <li>Full-scale network management ensuring unwavering reliability and security.</li>
                </ul>
                <button onClick={() => selectPlan("Enterprise", 244)}>Choose Plan</button>
            </div>
        </div>

            
      

         <br/>
         <br/>
         <br/>
         <br/>
         <br/>



    
            </section>
    );
};

export default PricingTable;