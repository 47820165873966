import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, updateProfile, sendPasswordResetEmail, deleteUser } from 'firebase/auth';

import { auth, firestore } from '../firebase';
import '../../Css/Pages/Profile.css';
import { doc, getDoc, updateDoc } from "firebase/firestore"; // Import getDoc
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase'; // Ensure you have initialized Firebase Storage in your firebase.js


const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [location, setLocation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [username, setUsername] = useState('');


  useEffect(() => {
   
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    const user = auth.currentUser;
    setUser(user);
    if (user) {
      try {
        const userRef = doc(firestore, "users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setFullName(userData.fullName);
          setPhoneNumber(userData.phoneNumber);
          setLocation(userData.location);
        } else {
          console.log("No user data found in Firestore");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setErrorMessage(error.message);
      }
    }
  };

  const handleUpdateProfile = async () => {
    try {
      let url = user.photoURL; // Keep the old URL by default
  
      // Upload new profile picture if selected
      if (profilePic) {
        const profilePicRef = ref(storage, `profilePics/${user.uid}/${profilePic.name}`);
        const snapshot = await uploadBytes(profilePicRef, profilePic);
        url = await getDownloadURL(snapshot.ref);
      }
  
      // Update profile with new picture and username
      await updateProfile(auth.currentUser, {
        displayName: username || fullName,
        photoURL: url,
      });
  
      // Update Firestore user document
      const userRef = doc(firestore, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        fullName,
        phoneNumber,
        location,
        photoURL: url,
        username: username || fullName,
      });
  
      alert('Profile updated successfully');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  
  const handleFileChange = (event) => {
    setProfilePic(event.target.files[0]);
  };

  
  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, auth.currentUser.email);
      alert('Password reset email sent!');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleSignOut = () => {
    getAuth().signOut().then(() => {
      navigate('/login');
    }).catch((error) => {
      setErrorMessage(error.message);
    });
  };

  const handleDeleteAccount = async () => {
    const confirmation = window.confirm('Are you sure you want to delete your account? This cannot be undone.');
    if (confirmation) {
      try {
        await deleteUser(auth.currentUser);
        navigate('/signup');
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      fetchUserDetails();
    }
  }, []);
  
  if (!user) return <div>Loading...</div>;

  return (
    <main className="main-content">
    <br/>
    <br/>
    <br/>
    <br/>

    <div className="profile-container">
      <h1>User Profile</h1>
      <div className="profile-form">
        <input type="file" onChange={handleFileChange} />
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
        <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" />
        <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
        <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Location" />
        <button onClick={handleUpdateProfile}>Update Profile</button>
        <button onClick={handleResetPassword}>Reset Password</button>
        <button onClick={handleSignOut}>Sign Out</button>
        <button className="danger" onClick={handleDeleteAccount}>Delete Account</button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    
    </main>
  );
};

export default Profile;