import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { collection, query, orderBy, onSnapshot, limit } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import '../../Css/Components/ClientViewBlog.css';
import { Helmet } from 'react-helmet';

const ClientViewBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState(['All']);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [loading, setLoading] = useState(true);
  const [visibleBlogs, setVisibleBlogs] = useState(3);

  useEffect(() => {
    const blogsQuery = query(collection(firestore, 'blogs'), orderBy('createdAt', 'desc'), limit(10));
    const unsubscribe = onSnapshot(blogsQuery, querySnapshot => {
      let allCategories = new Set(['All']);
      const blogsList = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const blogCategories = data.categories || [];
        if (Array.isArray(blogCategories)) {
          blogCategories.forEach(category => allCategories.add(category));
        } else if (typeof blogCategories === 'string') {
          allCategories.add(blogCategories);
        }
        return { id: doc.id, ...data, categories: blogCategories };
      });
      setBlogs(blogsList);
      setCategories(Array.from(allCategories));
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const filteredBlogs = blogs.filter(blog => 
    (selectedCategory === 'All' || (Array.isArray(blog.categories) && blog.categories.includes(selectedCategory))) &&
    (blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    blog.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (Array.isArray(blog.hashtags) && blog.hashtags.join(' ').toLowerCase().includes(searchTerm.toLowerCase())))
  );

  const showMoreBlogs = () => {
    setVisibleBlogs(prevVisible => prevVisible + 3);
  };

  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}/blogs`;

  return (
    <div>
      <br></br>
      <div className="blogs-container">
        <Helmet>
          <title>SmeadIT Blog - Latest Tech News and Insights</title>
          <meta name="description" content="Stay updated with the latest technology news, insights, and tips from SmeadIT." />
          <link rel="canonical" href={canonicalUrl} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="SmeadIT Blog - Latest Tech News and Insights" />
          <meta property="og:description" content="Stay updated with the latest technology news, insights, and tips from SmeadIT." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:image" content="../assets/Smeadit.png" />
          <link rel="alternate" hreflang="en-au" href="https://smeadit.au/blogs" />
          <link rel="alternate" hreflang="en" href="https://smeadit.com/blogs" />
        </Helmet>

      <center><h1 className="blog-header-title">Checkout SmeadIT's Blogs</h1></center>
      <div className="blog-filters">
        <input 
          type="text" 
          placeholder="Search blogs..." 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
          className="blog-search-bar"
        />
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="blog-category-dropdown"
        >
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>
      {loading ? (
        <div className="loading">Loading blogs...</div>
      ) : (
        <>
          <div className="blog-grid">
            {filteredBlogs.slice(0, visibleBlogs).map(blog => (
              <article key={blog.id} className="blog-card">
                {blog.featuredImage && <img src={blog.featuredImage} alt={blog.title} className="blog-card-image" />}
                <div className="blog-card-content">
                  <h2>{blog.title}</h2>
                  <div className="blog-preview" dangerouslySetInnerHTML={{ __html: blog.content.substring(0, 150) + '...' }} />
                  <div className="blog-meta">
                    <time dateTime={blog.createdAt?.toDate().toISOString()}>
                      {blog.createdAt?.toDate().toLocaleString()}
                    </time>
                    {Array.isArray(blog.hashtags) && <div className="blog-tags">{blog.hashtags.join(', ')}</div>}
                  </div>
                  <Link to={`/blogs/${blog.id}`} className="read-more-button">Read More</Link>
                </div>
              </article>
            ))}
          </div>
          {visibleBlogs < filteredBlogs.length && (
            <div className="show-more-container">
              <button onClick={showMoreBlogs} className="show-more-button">Show More</button>
            </div>
          )}
        </>
      )}
    </div>
    </div>
  );
};

export default ClientViewBlog;
