import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../../components/firebase';
import { doc, getDoc, addDoc, collection } from 'firebase/firestore';
import BookingSystem from '../../../components/Features/AdvancedBookingSystem';
import '../../Css/GetTechSupport.css'; // Assuming you put your CSS here

const GetTechSupport = () => {
  const [user] = useAuthState(auth);

  const services = {
    General: [
      "Computer Setup & Support",
      "Wi-Fi Configuration",
      "Phone Assistance",
      "General Tech Help",
      "Cable Management",
      "Device Setup",
    ],
    Advanced: [
      "Cybersecurity",
      "Data Backup & Recovery",
      "Software Help",
      "PC & Laptop Repair",
    ],
    Repairs: [
      "Screen Repairs",
      "Device Repairs",
      "Laptop Servicing",
    ],
    Setup: [
      "Smart Home Setup",
      "Entertainment System Setup",
      "Security System Setup",
      "Home Automation",
      "Smart Lighting",
    ],
    Development: [
      "Website Creation",
      "App Development",
    ],
    Creative: [
      "Video Editing",
    ],
    Other: [
      "Other Services (Specify in Details)"
    ]
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    location: '',
    selectedService: '',
    additionalInfo: '',
    date: '',
    time: '',
    // other necessary fields
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFormData({
            ...formData,
            name: userData.fullName || '',
            email: userData.email || '',
            location: userData.location || '',
            // Set other fields if available
          });
        }
      }
    };

    fetchUserData();
  }, [user, formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here, you can implement additional form validation if needed

    try {
      // Store booking data in Firestore
      await addDoc(collection(firestore, 'bookings'), {
        ...formData,
        userId: user.uid,
        createdAt: new Date()
      });

      // Reset form after successful submission
      setFormData({
        name: '',
        email: '',
        location: '',
        selectedService: '',
        additionalInfo: '',
        date: '',
        time: '',
        // Reset other fields as necessary
      });

      // Provide user feedback - for example, show a success message
      alert("Tech support request submitted successfully!");

    } catch (error) {
      console.error('Error submitting tech support request:', error);
      // Provide user feedback - for example, show an error message
      alert("Failed to submit request. Please try again.");
    }
  };

  return (
    <div>
      <br/>
      <br/>
      <br/>
      <br/>
  
    <div className='tech-support-container'>

   

      <h2>Get Tech Support</h2>
      <form onSubmit={handleSubmit} className="tech-support-form">
        <input 
          type="text" 
          name="name" 
          placeholder="Your Name" 
          className="form-input"
          value={formData.name} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="email" 
          name="email" 
          placeholder="Your Email" 
          className="form-input"
          value={formData.email} 
          onChange={handleChange} 
          required 
        />
        <div className="service-type-options">
          <label className="service-option">
            <input 
              type="radio" 
              name="serviceType" 
              value="online" 
              className="service-input"
              onChange={handleChange}
              checked={formData.serviceType === 'online'} 
            /> Online Service
          </label>
          <label className="service-option">
            <input 
              type="radio" 
              name="serviceType" 
              value="in-person" 
              className="service-input"
              onChange={handleChange}
              checked={formData.serviceType === 'in-person'} 
            /> In-Person
          </label>
        </div>

        {formData.serviceType === 'in-person' && (
          <>
            <input 
              type="text" 
              name="address" 
              placeholder="Address" 
              className="form-input"
              value={formData.address} 
              onChange={handleChange} 
            />
            <input 
              type="text" 
              name="postcode" 
              placeholder="Postcode" 
              className="form-input"
              value={formData.postcode} 
              onChange={handleChange} 
            />
          </>
        )}
        <select name="selectedService" className="form-select" value={formData.selectedService} onChange={handleChange} required>
          <option value="">Select a Service</option>
          {Object.entries(services).map(([category, services]) => (
            <optgroup label={category} key={category}>
              {services.map((service, index) => (
                <option key={index} value={service}>{service}</option>
              ))}
            </optgroup>
          ))}
        </select>
        <textarea 
          name="additionalInfo" 
          placeholder="Additional Information" 
          className="form-textarea"
          value={formData.additionalInfo} 
          onChange={handleChange} 
        />
        <BookingSystem formData={formData} setFormData={setFormData} />
        <button type="submit" className="submit-button">Submit Request</button>
      </form>
    </div>
  </div>
  );
};

export default GetTechSupport;
