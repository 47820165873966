import React, { useState, useEffect } from 'react';
import '../../Css/Components/ComingSoon.css'; // Make sure to link the CSS file

const calculateTimeLeft = (targetDate) => {
  const difference = +new Date(targetDate) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
};

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft('2024-01-01'));
  const [showCountdown, setShowCountdown] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft('2024-02-08'));
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="coming-soon-container">
      <div className="content">
        <h1>Coming Soon</h1>
        {showCountdown && (
          <div className="countdown-timer">
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
          </div>
        )}
        <p className="info-text">Our website is under construction. We'll be here soon with our new awesome site.</p>
      </div>
    </div>
  );
};

export default ComingSoon;
