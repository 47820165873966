import React from 'react';
import MySVG from '../assets/PriceList.svg'; 
import '../Css/Pages/SVGComponent.css';

const SVGComponent = () => {
    return (
        <div className="full-width-svg">
            <img src={MySVG} alt="Description of SVG content" />
            <br/>
            <br/>
        </div>
    );
};

export default SVGComponent;
