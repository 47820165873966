import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// Custom hooks and scripts
import { ScrollToTop } from './scripts/useScrollToTop'; 

// Firebase Authentication
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Stripe integration
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Navigation components
import Navbar from './components/Navs/Nav.js';
import DashNav from './components/Navs/DashNav.js';
import Footer from './components/Navs/footer.js';

// Modal and popup components
import HelpPopup from './components/Modals/HelpPopup.js';
import MessagePopup from './components/Modals/MessageButton.js';
import Modal from './components/Modals/Modal.js';
import ComingSoon from './components/Modals/ComingSoon.js';

// Page components
import Home from './pages/Home.js';
import About from './pages/About.js';
import Contact from './components/Contact';
import Getstarted from './pages/GetStarted.js';
import Features from './pages/Features.js';

import ServicePrices from './pages/ServicePrices.js';
import WebDevelopment from './pages/WebDevelopment.js';
import Webinars from './pages/WebinarsInfo.js';
import ServiceList from './pages/ServiceList.js'
import Refers from './components/Forms/Refer.js';


// Authentication pages
import LoginSignup from './authentication/LoginSignup';
import Dashboard from './authentication/Dashboard/Dashboard.js';
import Messages from './authentication/ClientMessage.js';
import Booking from './authentication/Booking.js';
import PreDash from './authentication/Dashboard/Pages/PreDash.js';
import PaymentMethods from './authentication/Dashboard/PaymentAndPlan.js';
import BookSupport from './authentication/Dashboard/Components/BookTechSupport.js';

// Dashboard components
import DeviceManagement from './authentication/Dashboard/Components/DeviceManagement.js';
import Support from './authentication/Dashboard/Components/SupportPage.js';
import Mybookings from './authentication/Dashboard/Components/Clientbooking.js';

// Feature components
import Quote from './components/Features/Quote.js';
import Profile from './components/Features/Profile.js';
import Updates from './components/Features/ClientViewUpdates.js';
import Blogs from './components/Features/ClientViewBlog.js';
import BlogDetail from './components/Features/BlogDetail.js';
import MyRequests from './components/Features/MyRequests.js';
import AddClient from './components/Forms/AddClient.js';

// Backend 
import Ai from './backend/ChatComponent.js';


// E-commerce components
import CheckoutPage from './components/CheckoutPage';
import Pricing from './components/PricingTable.js';

// Placeholder for future development
import PageUpdates from './components/Modals/ComingSoon.js';
import NotFound from './components/Modals/ComingSoon.js';

// Stripe promise initialization
const stripePromise = loadStripe('pk_live_51OUJ44IGXy7izLCvcomjlsENXScrOujxzbG0gVrXAIWqpLtYugbzxqf7gfVCSBmehTl8BS2WLdCfrFwsrRVgiwdJ007mNr3PgM');

const App = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
    <HelmetProvider>
      <ScrollToTop />
      <div className="App">
        {isLoggedIn ? (
          <DashNav setIsLoggedIn={setIsLoggedIn} />
        ) : (
          <Navbar setIsLoggedIn={setIsLoggedIn} />
        )}

        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <p>This is a modal message.</p>
        </Modal>

        <HelpPopup />
        <MessagePopup />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/smeadit" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/webdevelopment" element={<WebDevelopment />} />
          <Route path="/webinars" element={<Webinars/>} /> 
          <Route path="/servicelist" element={<ServiceList/>} />
          <Route path="/refers" element={<Refers />} />
          <Route path="/refer" element={<Refers />} />
          <Route path="/login" element={<LoginSignup setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/signup" element={<LoginSignup setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/shop" element={<ComingSoon />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/getstarted" element={<Getstarted />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/booking" element={<Quote />} />
          <Route path="/terms-and-conditions" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/ai" element={<Ai />} />
          <Route path="/checkout" element={
            <Elements stripe={stripePromise}>
              <CheckoutPage />
            </Elements>
          } />
          <Route path="/updates" element={<Updates />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blogs/:blogId" element={<BlogDetail />} />
          <Route path="/myrequests" element={<MyRequests />} />
          
          <Route path="/features" element={<Features />} />
          <Route path="/services" element={<ServiceList/>} />
          <Route path="/mybookings" element={<Mybookings />} />

          <Route path="/predash" element={<PreDash />} />
          <Route path="/paymentmethods" element={<PaymentMethods />} />
          <Route path="/booksupport" element={<BookSupport />} />
          <Route path="/serviceprices" element={<ServicePrices />} />
          <Route path="/pageupdates" element={<PageUpdates />} />
          <Route path="/devicemanagement" element={<DeviceManagement />} />
          <Route path="/support" element={<Support />} />
          <Route path="/addclient" element={<AddClient />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
      </HelmetProvider>
    </Router>
  );
}

export default App;
