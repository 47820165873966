import React, { useState, useEffect } from 'react';
import './ChatComponent.css';
import { auth, firestore } from '../components/firebase'; // Your Firebase setup
import { doc, getDoc } from 'firebase/firestore'; // Import required Firestore functions
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';


const ChatComponent = () => {
  const [input, setInput] = useState('');
  const [conversation, setConversation] = useState([]);
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSending, setIsSending] = useState(false);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userDocRef = doc(firestore, 'users', userAuth.uid); // Reference to the user's document
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUser(userDoc.data());
          setIsAuthorized(userDoc.data().status === 'Premium');
        }
      }
    });

    return unsubscribe; // Unsubscribe when the component unmounts
  }, []);

  const sendMessage = async (inputMessage) => {
    try {
      const response = await fetch('https://ai-server-hzk0.onrender.com/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: inputMessage })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.reply;
    } catch (error) {
      console.error('Error in sending message to server:', error);
      throw error;
    }
  };
  
  const sendMessageToServer = async () => {
    if (!input.trim() || isSending) {
      console.error("Cannot send an empty message.");
      return;
    }
  
    if (!isAuthorized) {
      alert('Please upgrade your account to send messages.');
      return;
    }
  
    setIsSending(true); // Disable sending another message
  
    try {
      const serverReply = await sendMessage(input);
  
      // Construct message objects for user and server
      const userMessage = { text: input, sender: 'user', timestamp: serverTimestamp() };
      const serverMessage = { text: serverReply, sender: 'bot', timestamp: serverTimestamp() };
  
      // Save the user message to Firestore (if needed)
      if (user && user.uid) {
        await addDoc(collection(firestore, 'chatMessages'), {
          userId: user.uid,
          message: input,
          timestamp: serverTimestamp()
        });
      }
  
      // Update the conversation state with both messages
      setConversation(convo => [...convo, userMessage, serverMessage]);
      setInput(''); // Clear input right after sending
    } catch (error) {
      alert('Error communicating with the server.');
    } finally {
      setIsSending(false); // Re-enable sending messages
    }
  };
  
  
  
  

  return (
  <div className='tops'>
    <center>
    <div className="ai-container">
      <div className="ai-window">
        <div className="message bot">
          Welcome {user ? user.fullName.split(" ")[0] : 'Guest'}!
        </div>
        {conversation.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.text}
          </div>
        ))}
      </div>
      <div className="ai-input">
      <input 
        type="text"
        placeholder='AI with SmeadIT Here...'
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && sendMessageToServer()}
        disabled={isSending}
      />
      <button onClick={sendMessageToServer} disabled={isSending}>Send</button>

      </div>
    </div>
    <br/>
    <br/>
    </center>

    </div>
  );
};

export default ChatComponent;
