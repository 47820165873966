import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Helmet } from 'react-helmet';
import './ReferralPage.css';
import { FaUser, FaEnvelope, FaPhone, FaPlus, FaCheck } from 'react-icons/fa';

const ReferralPage = () => {
  const [referrals, setReferrals] = useState([{ name: '', email: '', phone: '' }]);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const handleInputChange = (index, event) => {
    const values = [...referrals];
    values[index][event.target.name] = event.target.value;
    setReferrals(values);
  };

  const handleAddReferral = () => {
    if (referrals.length < 5) {
      setReferrals([...referrals, { name: '', email: '', phone: '' }]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!agreedToTerms) {
      setSubmissionStatus('Please agree to the terms.');
      return;
    }
    try {
      await addDoc(collection(firestore, 'referrals'), { referrals });
  
      await addDoc(collection(firestore, 'adminNotifications'), {
        eventType: 'Friend Referral',
        referrerName: referrals[0].name,
        referrerEmail: referrals[0].email,
        referrals: referrals,
        timestamp: serverTimestamp(),
        read: false,
      });
  
      setSubmissionStatus('Referral(s) submitted successfully! Thank you for your referral(s).');
      setReferrals([{ name: '', email: '', phone: '' }]);
      setAgreedToTerms(false);
      setCurrentStep(3);
    } catch (error) {
      setSubmissionStatus('Error submitting referral(s). Please try again.');
    }
  };

  const renderReferralForm = () => (
    <div className="referrals-container">
      {referrals.map((referral, index) => (
        <div key={index} className="referral-form">
          <h3>Referral #{index + 1}</h3>
          <div className="input-group">
            <div className="input-wrapper">
              <FaUser className="input-icon" />
              <input
                type="text"
                name="name"
                value={referral.name}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Full Name"
                required
              />
            </div>
            <div className="input-wrapper">
              <FaEnvelope className="input-icon" />
              <input
                type="email"
                name="email"
                value={referral.email}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Email Address"
                required
              />
            </div>
            <div className="input-wrapper">
              <FaPhone className="input-icon" />
              <input
                type="tel"
                name="phone"
                value={referral.phone}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Phone Number"
                required
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderStepIndicator = () => (
    <div className="step-indicator">
      <div className={`step ${currentStep >= 1 ? 'active' : ''}`}>1. Enter Referrals</div>
      <div className={`step ${currentStep >= 2 ? 'active' : ''}`}>2. Review & Submit</div>
      <div className={`step ${currentStep >= 3 ? 'active' : ''}`}>3. Confirmation</div>
    </div>
  );

  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}/refer`;

  return (
    <div className="referral-page">
      <Helmet>
        <title>Refer a Friend For a Free SmeadIT Tech Support</title>
        <meta name="description" content="Refer your friends to SmeadIT and earn free tech support. Join our referral program today and help your network get top-notch IT services." />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Refer a Friend - SmeadIT Tech Support" />
        <meta property="og:description" content="Refer your friends to SmeadIT and earn free tech support. Join our referral program today!" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="../assets/Smeadit.png" />
        <link rel="alternate" hreflang="en-au" href="https://smeadit.au/refer" />
        <link rel="alternate" hreflang="en" href="https://smeadit.com/refer" />
      </Helmet>

      <div className="referral-content">
        <h1>Refer Friends to SmeadIT</h1>
        {renderStepIndicator()}

        {currentStep === 1 && (
          <form onSubmit={(e) => { e.preventDefault(); setCurrentStep(2); }}>
            {renderReferralForm()}
            <button type="button" onClick={handleAddReferral} className="add-referral-btn">
              <FaPlus /> Add Another Referral (Optional)
            </button>
            <button type="submit" className="next-step-btn">Review Referral(s)</button>
          </form>
        )}

        {currentStep === 2 && (
          <div className="review-step">
            <h2>Review Your Referrals</h2>
            {referrals.map((referral, index) => (
              <div key={index} className="review-referral">
                <h4>Referral #{index + 1}</h4>
                <p><strong>Name:</strong> {referral.name}</p>
                <p><strong>Email:</strong> {referral.email}</p>
                <p><strong>Phone:</strong> {referral.phone}</p>
              </div>
            ))}
    <div className="terms-container">
      
      <br></br>
      <br></br>
    <label htmlFor="terms-agreement">I agree to the terms and conditions</label>
    
    <input
        type="checkbox"
        id="terms-agreement"
        checked={agreedToTerms}
        onChange={() => setAgreedToTerms(!agreedToTerms)}
    />
</div>


            <button onClick={handleSubmit} className="submit-btn">Submit Referral(s)</button>
          </div>
        )}

        {currentStep === 3 && (
          <div className="confirmation-step">
            <FaCheck className="confirmation-icon" />
            <h2>Thank You for Your Referrals!</h2>
            <p>{submissionStatus}</p>
            <button onClick={() => setCurrentStep(1)} className="start-over-btn">Refer More Friends</button>
          </div>
        )}

        {submissionStatus && <p className="submission-status">{submissionStatus}</p>}
      </div>
    </div>
  );
};

export default ReferralPage;
