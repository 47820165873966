import React, { useState } from 'react';
import { firestore } from '../firebase'; // Adjust the import path as necessary
import { collection, addDoc } from "firebase/firestore";
import './ClientForm.css';

const AddClient = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      number: '',
      abnTfn: '',
      location: '',
      details: ''
    });
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await addDoc(collection(firestore, "clients"), formData);
        alert("Client added successfully!");
        setFormData({
          name: '',
          email: '',
          number: '',
          abnTfn: '',
          location: '',
          details: ''
        }); // Reset form after successful submission
      } catch (error) {
        console.error("Error adding document: ", error);
        alert("An error occurred while adding the client.");
      }
    };
  
    return (
        <div className="form-container">
          <form onSubmit={handleSubmit} className="client-form">
            <h2 className="form-title">Create Client Profile</h2>
            <input className="form-input" type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
            <input className="form-input" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
            <input className="form-input" type="tel" name="number" placeholder="Phone Number" value={formData.number} onChange={handleChange} required />
            <input className="form-input" type="text" name="abnTfn" placeholder="ABN/TFN" value={formData.abnTfn} onChange={handleChange} />
            <input className="form-input" type="text" name="location" placeholder="Location" value={formData.location} onChange={handleChange} required />
            <input className="form-input" type="text" name="serviceType" placeholder="Service Type" value={formData.serviceType} onChange={handleChange} />
            <label>
                Birthday
            <input className="form-input" type="date" name="serviceDate" placeholder="Service Date" value={formData.serviceDate} onChange={handleChange} />
            </label>
            <textarea className="form-textarea" name="details" placeholder="Other Details" value={formData.details} onChange={handleChange} />
            <select className="form-select" name="contactPreference" value={formData.contactPreference} onChange={handleChange} required>
              <option value="">Contact Preference</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
              <option value="none">None</option>
            </select>
            <button className="form-button" type="submit">Create Profile</button>
          </form>
        </div>
      );
  };
  
  export default AddClient;

