import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, getDoc, collection, getDocs, query, orderBy } from 'firebase/firestore';
import '../../Css/Components/ClientViewBlog.css';
import { Helmet } from 'react-helmet';

const BlogDetail = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogDoc = doc(firestore, 'blogs', blogId);
        const docSnap = await getDoc(blogDoc);

        if (docSnap.exists()) {
          let blogData = { id: docSnap.id, ...docSnap.data() };

          if (blogData.contentChunks > 1) {
            const chunksQuery = query(collection(firestore, `blogs/${blogId}/chunks`), orderBy('index'));
            const chunksSnap = await getDocs(chunksQuery);
            let fullContent = blogData.content;
            chunksSnap.forEach(chunkDoc => {
              fullContent += chunkDoc.data().content;
            });
            blogData.content = fullContent;
          }

          setBlog(blogData);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogId]);
  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}/blogs/${blogId}`;

  if (loading) {
    return <div className="blog-detail-loading">Loading...</div>;
  }

  if (!blog) {
    return <div className="blog-detail-error">Blog not found</div>;
  }

  return (
    <div className="blog-detail-wrapper">
      <Helmet>
        <title>{blog.seoTitle || blog.title} - SmeadIT Blog</title>
        <meta name="description" content={blog.seoDescription || blog.content.substring(0, 160)} />
        <meta name="keywords" content={Array.isArray(blog.seoKeywords) ? blog.seoKeywords.join(', ') : blog.seoKeywords} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${blog.seoTitle || blog.title} - SmeadIT Blog`} />
        <meta property="og:description" content={blog.seoDescription || blog.content.substring(0, 160)} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={blog.featuredImage || "../assets/Smeadit.png"} />
        <link rel="alternate" hreflang="en-au" href={`https://smeadit.au/blogs/${blogId}`} />
        <link rel="alternate" hreflang="en" href={`https://smeadit.com/blogs/${blogId}`} />
      </Helmet>

      <article className="blog-detail-container">
        <header className="blog-detail-header">
          <div className={`blog-image-container ${blog.featuredImagePosition} ${blog.featuredImageSize}`}>
            {blog.featuredImage && <img src={blog.featuredImage} alt={blog.title} className="blog-detail-image" />}
            <div className="blog-title-overlay">
              <h1 className={`blog-detail-title ${blog.titleColor} ${blog.titleSize}`}>{blog.title}</h1>
            </div>
          </div>
        </header>
        <section className="blog-detail-content" dangerouslySetInnerHTML={{ __html: blog.content }} />
        <footer className="blog-detail-footer">
          {blog.categories && (
            <div className="blog-categories">
              Categories: {Array.isArray(blog.categories) ? blog.categories.join(', ') : blog.categories}
            </div>
          )}
          {blog.tags && (
            <div className="blog-tags">
              Tags: {Array.isArray(blog.tags) ? blog.tags.join(', ') : blog.tags}
            </div>
          )}
          <div className="blog-meta">
            <time dateTime={blog.publishedAt?.toDate().toISOString()}>
              Published on: {blog.publishedAt?.toDate().toLocaleString()}
            </time>
          </div>
        </footer>
      </article>
    </div>
  );
};

export default BlogDetail;
