import React, { useState, useEffect } from 'react';
import { collection, addDoc, onSnapshot, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../components/firebase'; // Ensure this path is correct
import '../../Css/DeviceManager.css';

const DeviceRegistrationForm = ({ onAddDevice }) => {
  const [deviceName, setDeviceName] = useState('');
  const [deviceType, setDeviceType] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddDevice({ name: deviceName, type: deviceType });
    setDeviceName('');
    setDeviceType('');
  };

  return (
    <div className="device-management-container">
      <h2 className="device-management-title">Add New Device</h2>
      <form onSubmit={handleSubmit}>
        <input
          className="device-input"
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value)}
          placeholder="Device Name"
          required
        />
        <input
          className="device-input"
          value={deviceType}
          onChange={(e) => setDeviceType(e.target.value)}
          placeholder="Device Type"
          required
        />
        <button type="submit" className="device-action-btn">Add Device</button>
      </form>
    </div>
  );
};


const ServiceBookingForm = ({ device, onSubmit, onCancel }) => {
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ date, description });
    onCancel(); // Reset form
  };

  return (
    <div className="device-management-container">
      <h2 className="device-management-title">Book a Service for {device.name}</h2>
      <form onSubmit={handleSubmit}>
        <input
          className="device-input"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
        <textarea
          className="device-textarea"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Service Description"
          required
        />
        <button type="submit" className="device-action-btn">Book Service</button>
        <button type="button" className="device-action-btn" onClick={onCancel}>Cancel</button>
      </form>
    </div>
  );
};
const DeviceManager = () => {
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
  
    useEffect(() => {
      const unsubscribe = onSnapshot(collection(firestore, 'devices'), (snapshot) => {
        const fetchedDevices = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDevices(fetchedDevices);
      });
  
      return unsubscribe;
    }, []);
  
    const addDevice = async (device) => {
      await addDoc(collection(firestore, 'devices'), device);
    };
  
    const bookService = async (bookingDetails) => {
      const deviceRef = doc(firestore, 'devices', selectedDevice.id);
      const updatedBookings = selectedDevice.bookingDetails ? [...selectedDevice.bookingDetails, bookingDetails] : [bookingDetails];
      await updateDoc(deviceRef, { bookingDetails: updatedBookings });
      setSelectedDevice(null); // Reset after booking
    };
  
    const deleteDevice = async (deviceId) => {
      await deleteDoc(doc(firestore, 'devices', deviceId));
    };
  
    // Render devices with updated class names
    return (
      <main className="main-content">
        <DeviceRegistrationForm onAddDevice={addDevice} />
        {devices.map(device => (
          <div key={device.id} className="device-management-container">
            <h3 className="device-management-title">{device.name} ({device.type})</h3>
            {device.bookingDetails && device.bookingDetails.map((booking, index) => (
              <p key={index}>{booking.date} - {booking.description}</p>
            ))}
            <button onClick={() => setSelectedDevice(device)} className="device-action-btn">Book Service</button>
            <button onClick={() => deleteDevice(device.id)} className="device-action-btn">Delete Device</button>
          </div>
        ))}
        {selectedDevice && (
          <ServiceBookingForm
            device={selectedDevice}
            onSubmit={bookService}
            onCancel={() => setSelectedDevice(null)}
          />
        )}
      </main>
    );
  };
  
  export default DeviceManager;