import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import '../../Css/Other/DropDown.css'; // Custom styles

const Dropdown = () => {
  const [activeIndex, setActiveIndex] = useState(0); // First item open by default

  const toggleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    { question: "Why choose SmeadIT?", answer: "SmeadIT aspires to provide the highest quality of IT support and web development services. We aim to provide family-like care, meaning we treat you like family, giving you reasonable pricing, ongoing quality service, and aiming to resolve more than one problem each time. We understand that there are probably many things that you may need help with. At SmeadIT, we're there for it all and won't stop until you're satisfied." },
    
    { question: "How long is each session?", answer: "The duration of each session depends on the nature of the service provided. For IT support, sessions typically range from 30 minutes to an hour, ensuring comprehensive troubleshooting and resolution. Web development projects involve longer timelines, with ongoing collaboration and milestone-based progress reviews. We strive to be flexible and responsive, adjusting session lengths to best accommodate our clients' needs and schedules." },
    
    { question: "What types of services do you offer?", answer: "SmeadIT offers a wide variety of services, including general tech support for your phone, computer, laptop, and more, as well as advanced services like web and app development for small businesses." },
    
    { question: "What types of payment can I choose?", answer: "We offer flexible payment options for your convenience. You can choose between one-time payments for individual sessions or projects and recurring billing for ongoing services such as monthly memberships. We accept credit and debit cards, as well as invoicing and direct banking. Our billing process is transparent, ensuring clarity in costs, and can be customized to accommodate each client's budget and preferences." },
    
    { question: "Can I have recurring service?", answer: "At SmeadIT, we believe that meaningful connections are key to providing excellent IT support. Our personalized services are designed to fit your needs, whether it's for a one-time solution or ongoing assistance. We understand that ongoing support is crucial for many clients, and our recurring service options provide regular maintenance and assistance, ensuring your technology stays in top shape. This service is ideal for a variety of clients, including elderly individuals, small businesses, and enterprises seeking proactive solutions. We're here to build lasting relationships and tailor our support to meet your unique needs." },

    { question: "How many days a week are you available?", answer: "SmeadIT is available six days a week, offering support and services from Monday to Saturday. Usually from 9am - 6pm We understand the importance of timely assistance, and we're always happy to accommodate your schedule. You can check our availability and times on google" },

   
  ];

  return (
    <div className="accordion-container">
      {faqs.map((faq, index) => (
        <div key={index} className="accordion-item">
          <div className="accordion-header" onClick={() => toggleDropdown(index)}>
            <span>{faq.question}</span>
            {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          <div className={`accordion-content ${activeIndex === index ? 'open' : ''}`}>
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
