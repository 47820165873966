import React from 'react';
import '../Css/Pages/ServicesPage2.css';
import serviceImage from '../assets/info.jpg';
import Features from './Features';
import { Helmet } from 'react-helmet';
import { useNavigate, Link } from 'react-router-dom';

const servicesData = [
    {
        category: 'IT Support',
        description: 'Comprehensive IT support for businesses, individuals, seniors, and schools.',
        services: [
            'Business IT Support',
            'Individual IT Support',
            'Senior IT Support',
            'School IT Support'
        ]
    },
    {
        category: 'Web Development',
        description: 'Creating stunning and functional websites tailored to your needs.',
        services: [
            'Custom Website Design',
            'Website Maintenance',
            'E-commerce Solutions',
            'SEO Optimization'
        ]
    },
    {
        category: 'Additional Services',
        description: 'Variety of additional tech services to cover all your needs.',
        services: [
            'Personalized Training',
            'Weekly Tech Webinars',
            'Smart Home Setup',
            'Data Recovery'
        ]
    }
];

const ServicesPage = () => {
    const currentDomain = window.location.hostname;
    const canonicalUrl = `https://${currentDomain}${window.location.pathname}`;

    return (
        
        <div className="services3sections-page">
                   <Helmet>
                <title>Services at SmeadIT</title>
                <meta name="description" content="Smeadit's Services, We offer all things technology big or small for personalized tech support and innovative solutions to enhance your technology experience. Explore our services today." />
                <meta name="keywords" content="Smeadit Services, SmeadIT Tech support, Service, tech support, technology solutions, tech assistance, tech innovations" />
                <meta property="og:title" content="SmeadIT Services" />
                <meta property="og:description" content="Smeadit Services, SmeadIT Tech support, Service, tech support, technology solutions, tech assistance, tech innovations." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={canonicalUrl} />
                <meta property="og:image" content="../assets/Smeadit.png" />
                <link rel="canonical" href={canonicalUrl} />
                <link rel="alternate" hreflang="en-au" href="https://smeadit.au/services" />
                <link rel="alternate" hreflang="en" href="https://smeadit.com/services" />
            </Helmet>

          <br/>
          <br/>
          <br/>
          <br/>
          <div className="services-hero-section">
  <div className="services-hero-content">
    <h1 className="services-hero-title">Our Services</h1>
    <p className="services-hero-subtitle">All Things Tech - For Businesses, Individuals, Seniors, and Schools</p>
    <p className="services-hero-promo">Any tech assistance you need for as low as $60</p>
    <div className="services-hero-cta">
      <Link to="/serviceprices" className="cta-button primary">See Our Pricing Options</Link>
      <Link to="/getstarted" className="cta-button secondary">Choose the Right Service for You</Link>
    </div>
  </div>
  <div className="services-hero-image">
    <img src={serviceImage} alt="SmeadIT Services" />
  </div>
</div>

            <div className="services3sections-container">
                {servicesData.map((serviceCategory, index) => (
                    <div className="services3sections-category" key={index}>
                        <h2 className="services3sections-category-title">{serviceCategory.category}</h2>
                        <p className="services3sections-category-description">{serviceCategory.description}</p>
                        <ul className="services3sections-service-list">
                            {serviceCategory.services.map((service, index) => (
                                <li className="services3sections-service-item" key={index}>{service}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <br/>
            <br/>
            <Features />
        </div>
    );
};

export default ServicesPage;
