import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';


const firebaseConfig = {
    apiKey: "AIzaSyClLLb9pwG8zM804j_yWIfLaJoyUPmwBBQ",
    authDomain: "smeadit-1fab6.firebaseapp.com",
    databaseURL: "https://smeadit-1fab6-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "smeadit-1fab6",
    storageBucket: "smeadit-1fab6.appspot.com",
    messagingSenderId: "431137308549",
    appId: "1:431137308549:web:592de471c2c3e82c98ceec",
    measurementId: "G-K0RQC8NBSY"



    // apiKey: "AIzaSyDbOHXXwD1RRjX6hTQRR_OVnq3Wjl3BseA",
    // authDomain: "smeadit-75042.firebaseapp.com",
    // databaseURL: "https://smeadit-75042-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "smeadit-75042",
    // storageBucket: "smeadit-75042.appspot.com",
    // messagingSenderId: "47078257704",
    // appId: "1:47078257704:web:e39bd27894780ec245f11d",
    // measurementId: "G-H9T06WM38V"
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);