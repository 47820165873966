import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, addDoc, serverTimestamp, setDoc, doc} from 'firebase/firestore';


import { auth, firestore } from '../components/firebase';
import '../Css/Pages/WebinarRegistration.css';
import { Helmet } from 'react-helmet';

import { FaLaptop, FaUserGraduate, FaChalkboardTeacher, FaUserFriends } from 'react-icons/fa';

const WebinarsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    groupCode: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, groupCode } = formData;
  
    try {
      await setDoc(doc(collection(firestore, 'webinarsSignUps')), {
        name,
        email,
        groupCode,
        timestamp: new Date(),
      });
  
      // Add a notification for webinar signup
      await addDoc(collection(firestore, 'adminNotifications'), {
        eventType: 'Webinar Signup',
        userName: name,
        userEmail: email,
        groupCode,
        timestamp: serverTimestamp(),
        read: false,
      });
  
      setFormSubmitted(true);
      setFormError('');
    } catch (error) {
      console.error('Error adding document: ', error);
      setFormError('Failed to submit. Please try again.');
    }
  };
  
  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}/webinars`;

  return (
    <div className="webinars-page">
      <Helmet>
        <title>Tech Webinars by SmeadIT</title>
        <meta name="description" content="SmeadIT offers weekly webinars to enhance your tech skills, starting at $52/month. Available on Mondays and Tuesdays." />
        <meta name="keywords" content="SmeadIT, tech webinars, tech skills, online learning, beginner tech skills, intermediate tech skills, advanced tech skills, tech competency, Excel, computer skills" />
        <meta property="og:title" content="Tech Webinars by SmeadIT - Enhance Your Tech Skills" />
        <meta property="og:description" content="Join SmeadIT's weekly webinars to improve your tech skills. Suitable for beginners, intermediate, and advanced levels." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="../assets/Smeadit.png" />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" hreflang="en-au" href="https://smeadit.au/webinars" />
        <link rel="alternate" hreflang="en" href="https://smeadit.com/webinars" />
      </Helmet>

      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <header className="webinars-header">
        <h1>Weekly Tech Webinars</h1>
        <p>Starting at $52/month. Available on Mondays and Tuesdays.</p>
      </header>

      <section className="webinars-info">
        <div className="webinars-info-section">
          <FaLaptop className="info-icon" />
          <h2>Building Tech Competency</h2>
          <p>Enhance your skills with our weekly webinars. Suitable for all levels: beginners, intermediate, and advanced.</p>
        </div>
        <div className="webinars-info-section">
          <FaUserGraduate className="info-icon" />
          <h2>Beginner, Intermediate, Advanced</h2>
          <p>Our webinars are categorized to help you learn at your own pace. Start from the basics and move up to advanced topics.</p>
        </div>
        <div className="webinars-info-section">
          <FaChalkboardTeacher className="info-icon" />
          <h2>Customizable Agendas</h2>
          <p>Submit your weekly agenda to cover topics like Excel, general computer skills, and more. Tailor your learning experience to your needs.</p>
        </div>
      </section>

      <section className="webinars-signup">
        <h2>Join Our Webinars</h2>
        {formSubmitted ? (
          <p className="success-message">Thank you for signing up! We will be in touch soon.</p>
        ) : (
          <form className="signup-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input type="text" id="name" placeholder="Enter your full name" required onChange={handleChange} value={formData.name} />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" placeholder="Enter your email" required onChange={handleChange} value={formData.email} />
            </div>
            <div className="form-group">
              <label htmlFor="group-code">Group Code (if any)</label>
              <input type="text" id="group-code" placeholder="Enter your group code" onChange={handleChange} value={formData.groupCode} />
            </div>
            {formError && <p className="error-message">{formError}</p>}
            <button type="submit" className="signup-button">Sign Up</button>
          </form>
        )}
      </section>
    </div>
  );
};

export default WebinarsPage;