import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { firestore } from '../../../components/firebase';

const ClientBooking = () => {
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);

    useEffect(() => {
        const fetchTimeSlots = async () => {
            const q = query(collection(firestore, "timeSlots"), where("isBooked", "==", false));
            const querySnapshot = await getDocs(q);
            let slots = [];
            querySnapshot.forEach((doc) => {
                slots.push({ id: doc.id, ...doc.data() });
            });
            setTimeSlots(slots);
        };

        fetchTimeSlots();
    }, []);

    const handleBooking = async (slot) => {
        setSelectedSlot(slot);
        const slotRef = doc(firestore, "timeSlots", slot.id);
        await updateDoc(slotRef, {
            isBooked: true
        });
        // Further actions like sending confirmation can be added here
    };

    return (
        <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            

            <h1>Book Your Tech Support Session</h1>
            <div>
                {timeSlots.map(slot => (
                    <button key={slot.id} onClick={() => handleBooking(slot)}>
                        {slot.time} {/* Display slot time */}
                    </button>
                ))}
            </div>
            {selectedSlot && <p>You have booked: {selectedSlot.time}</p>}
        </div>
    );
};

export default ClientBooking;
