import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import '../../Css/Components/Nav.css';
import Logo from '../../assets/LogoUpdate.png';
import { FaHome, FaUserAlt, FaTools, FaGlobe ,FaShoppingCart, FaSignInAlt, FaBars, FaEnvelope, FaChevronDown, FaPhone, FaDollarSign } from 'react-icons/fa';

const Navbar = ({ setIsLoggedIn }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedInState] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [buttonText, setButtonText] = useState('Book Online');
  const auth = getAuth();

  const buttonTextOptions = ['Book Online'];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedInState(!!user);
      if (user) {
        setIsLoggedIn(true);
      }
    });

    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    const buttonTextChangeInterval = setInterval(() => {
      setButtonText(prevText => {
        const currentIndex = buttonTextOptions.indexOf(prevText);
        const nextIndex = (currentIndex + 1) % buttonTextOptions.length;
        return buttonTextOptions[nextIndex];
      });
    }, 20000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      unsubscribe();
      clearInterval(buttonTextChangeInterval);
    };
  }, [setIsLoggedIn]);



  const handleSignOut = () => {
    signOut(auth).then(() => {
      setIsLoggedInState(false);
      setIsLoggedIn(false);
    }).catch((error) => {
      console.error('Sign Out Error', error);
    });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  return (
    <div className="navbar">
      {/* <div className="alert-bar">

      <p>Scheduled website maintenance this weekend!</p>
    </div> */}






      <nav className={`navbar ${scrolled ? 'scrolled' : ''} ${isLoggedIn ? 'logged-in' : ''}`}>
      
        <div className="logo">

          {!isLoggedIn && <Link to="/"><img src={Logo} alt="Logo" /></Link>}
        </div>
        
        <button className="side-nav-toggle" onClick={toggleSideNav}><FaBars /></button>
        <div className={`nav-links ${sideNavOpen ? 'open' : ''}`}>
          {isLoggedIn ? (
            <>
              <Link to="/dashboard" onClick={toggleSideNav}><FaBars /> Dashboard</Link>
              <Link to="/shop" onClick={toggleSideNav} className="beta"><FaShoppingCart /> Shop</Link>
              <Link to="/profile" className="profile-pic" onClick={toggleSideNav}><FaUserAlt />Profile</Link>
              <button onClick={handleSignOut} className='quote-btn'>Sign Out</button>
            </>
          ) : (
            <>
              <Link to="/" onClick={toggleSideNav}><FaHome /> Home</Link>

              <Link to="/services" onClick={toggleSideNav} ><FaTools />Services</Link>
              {/* <Link to="/webdevelopment" onClick={toggleSideNav} ><FaGlobe /> Web Development</Link> */}
              <Link to="/contact" onClick={toggleSideNav}><FaEnvelope /> Contact</Link>
              <div className="dropdown">
                <button onClick={toggleDropdown}>More <FaChevronDown /></button>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    
                     {/* <Link to="/about">About</Link>  */}
                     {/* <Link to="/webdevelopment">Web-Development</Link> */}
                     {/* <Link to="/features">Features</Link> */}
                     <Link to="/about">About</Link> 
                     <Link to="/webinars">Webinars</Link>
                    <Link to="/blogs">Blog</Link>
                    <Link to="/updates">Updates</Link>
                
                  </div>
                )}
              </div>
              <Link to="/booking"><button className="quote-btn" onClick={toggleSideNav}>{buttonText}</button></Link>
            </>
          )}


        </div>
      </nav>
    </div>
  );
};

export default Navbar;