import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faBars,
  faUserAlt,
  faEnvelope,
  faPhone,
  faBell,
  faQuestionCircle,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FaHome, FaSignInAlt } from "react-icons/fa";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import "../../authentication/Css/DashNav.css";
import profilePic from "../../assets/avatar.png";

const DashNav = ({ setIsLoggedIn }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [accountType, setAccountType] = useState("Basic");
  const [isLoggedIn, setIsLoggedInState] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationDropdownOpen, setNotificationDropdownOpen] =
    useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setIsLoggedInState(!!currentUser);
      if (currentUser) {
        setUser(currentUser);
        setIsLoggedIn(true);
        fetchNotifications(currentUser.uid);
        await fetchAccountType(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, [auth, setIsLoggedIn]);

  const fetchAccountType = async (userId) => {
    try {
      const userDocRef = doc(firestore, "users", userId); // Adjust path to your users collection
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setAccountType(userData.plan || "Basic"); // Assuming 'accountType' field exists
      } else {
        console.log("No such user document!");
      }
    } catch (error) {
      console.error("Error fetching user document: ", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchNotifications(user.uid);
    }
  }, [user]);

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString();
  };

  const handleNotificationClick = async (event, notification) => {
    event.stopPropagation();

    if (!notification || !notification.id) {
      console.error("Invalid notification data");
      return;
    }

    const notificationRef = doc(firestore, "notifications", notification.id);
    await updateDoc(notificationRef, { read: true });

    fetchNotifications(user.uid);

    navigate("/messages");
  };

  const fetchNotifications = async (userId) => {
    try {
      const notificationsQuery = query(
        collection(firestore, "notifications"),
        where("userId", "==", userId),
        where("read", "==", false),
        orderBy("timestamp", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(notificationsQuery);
      const fetchedNotifications = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Add the document ID to each notification
        ...doc.data(),
      }));
      console.log("Fetched Notifications:", fetchedNotifications); // Check fetched data
      setNotifications(fetchedNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const toggleNotificationDropdown = () => {
    setNotificationDropdownOpen(!notificationDropdownOpen);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setIsLoggedIn(false);
      navigate("/login");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  if (!user) {
    return <div>Loading user information...</div>;
  }

  return (
    <div className="dash-nav-container">
      <div className="top-nav">
        <button className="side-nav-toggle" onClick={toggleSideNav}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className="user-info">
          <Link to="/pricing" className="profile-link">
            <img
              src={user.photoURL || profilePic}
              alt="Profile"
              className="profile-pic"
            />
          </Link>
          <Link to="/pricing" className="profile-link">
            <div className="user-details">
              <span className="user-name">
                {user.displayName || "Username"}
              </span>
              <span className="account-type">Account Type: {accountType}</span>
            </div>
          </Link>
        </div>
        <div className={`nav-links ${sideNavOpen ? "open" : ""}`}>
          {isLoggedIn ? (
            <>
              <Link to="/dashboard" onClick={toggleSideNav}>
                <FontAwesomeIcon icon={faBars} /> Dashboard
              </Link>

              <Link to="/profile" onClick={toggleSideNav}>
                <FontAwesomeIcon icon={faUserAlt} /> Profile
              </Link>
              <Link to="/messages">
                <FontAwesomeIcon icon={faEnvelope} /> Messages
              </Link>
              <Link to="/pageupdates" onClick={toggleSideNav}>
                <FontAwesomeIcon icon={faQuestionCircle} /> Help
              </Link> 
              {/* <Link to="/clientcall" onClick={toggleSideNav}>
                <FontAwesomeIcon icon={faPhone} /> Call
              </Link> */}
              <div className="alert-icon" onClick={toggleNotificationDropdown}>
                <FontAwesomeIcon icon={faBell} />

                <span className="notification-badge">
                  {notifications.length}
                </span>
                {notificationDropdownOpen && (
                  <div className="notification-dropdown">
                    {notifications.map((notification) => (
                      <div
                        key={notification.id}
                        className="notification-item"
                        onClick={(e) =>
                          handleNotificationClick(e, notification)
                        }
                      >
                        <h4>Message: {notification.messageText}</h4>
                        <p>Date: {formatDate(notification.timestamp)}</p>
                      </div>
                    ))}
                    {notifications.length === 0 && <p>No notifications</p>}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Link to="/login">
                <FaSignInAlt /> Login
              </Link>
              <div className="dropdown">
                <button onClick={toggleDropdown}>More</button>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    <Link to="/about">About</Link>
                    <Link to="/features">Features</Link>
                    <Link to="/pricing">Pricing</Link>
                    <Link to="/updates">Updates</Link>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashNav;
