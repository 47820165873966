import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';

const BookingSystem = ({ formData, setFormData }) => {
  const [selectedDate, setSelectedDate] = useState(null); 
  const [availableSlots, setAvailableSlots] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAvailableSlots = async () => {
    if (!selectedDate) return; 
    setLoading(true);
    const q = query(collection(firestore, "bookings"), where("date", "==", selectedDate));
    const querySnapshot = await getDocs(q);
    let bookedSlots = [];
    querySnapshot.forEach((doc) => {
      bookedSlots.push(doc.data().time);
    });

    const allSlots = Array.from({ length: 5 }, (_, i) => {
      let hour = 9 + 1.5 * i;
      let hourString = `${Math.floor(hour)}:${hour % 1 === 0 ? '00' : '30'}`;
      return hourString;
    });
    const freeSlots = allSlots.filter(slot => !bookedSlots.includes(slot));
    setAvailableSlots(freeSlots);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedDate) {
      fetchAvailableSlots();
    }
  }, [selectedDate]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    setFormData({ ...formData, bookingDate: newDate });
  };

  const handleTimeChange = (e) => {
    setFormData({ ...formData, bookingTime: e.target.value });
  };

  const minDate = () => {
    const result = new Date();
    result.setDate(result.getDate() + 1);
    return result.toISOString().split('T')[0];
  };

  return (
    
    <div style={{ margin: '10px 0' }}>
      <label htmlFor="bookingDate" style={{ marginRight: '10px' }}>Choose Date:</label>
      <input
        type="date"
        id="bookingDate"
        name="bookingDate"
        value={selectedDate || ''} 
        onChange={handleDateChange}
        min={minDate()}
      />

      <br />

      <label htmlFor="bookingTime" style={{ marginRight: '10px', marginTop: '10px' }}>Select Time Slot:</label>
      <select
        id="bookingTime"
        name="bookingTime"
        onChange={handleTimeChange}
        disabled={loading || !selectedDate}
        style={{ marginTop: '10px' }}
      >
        <option value="">Select a Time Slot</option>
        {availableSlots.map((slot, index) => (
          <option key={index} value={slot}>{slot}</option>
        ))}
      </select>
    </div>
  );
};

export default BookingSystem;