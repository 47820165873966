import React, { useState } from 'react';
import '../Css/Pages/Features.css';
import FeatureModal from '../components/Modals/FeatureModal';
import { Helmet } from 'react-helmet';
import {
  FaDesktop, FaWifi, FaMobile, FaPrint, FaQuestionCircle, FaRecycle,
  FaShieldAlt, FaUserGraduate, FaToolbox, FaHome, FaCode, FaLaptopCode,
  FaLightbulb, FaTabletAlt, FaShoppingCart, FaScrewdriver, FaBug, FaHandsHelping,
  FaTv, FaWrench, FaClock, FaUserShield, FaRocket, FaLayerGroup, FaUsers, FaSearch,
  FaCloud, FaGamepad, FaAmbulance, FaMobileAlt, FaBullhorn
} from "react-icons/fa";

const Features = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');

  const categories = ['All', 'IT Support', 'Web Development', 'Additional Services'];

  const initialServices = [
    {
      id: 1,
      category: 'IT Support',
      title: "PC Setup & Support",
      shortDescription: "Setup, install software, and troubleshoot PCs.",
      detailedDescription: "Comprehensive PC setup, software installation, and ongoing support. Available 24/7.",
      icon: FaDesktop,
      suggestedPricing: "$60",
    },
    {
      id: 2,
      category: 'IT Support',
      title: "Wi-Fi Setup",
      shortDescription: "Setup and secure your home Wi-Fi network.",
      detailedDescription: "Complete Wi-Fi setup, configuration, and security for seamless connectivity.",
      icon: FaWifi,
      suggestedPricing: "$60",
    },
    {
      id: 3,
      category: 'IT Support',
      title: "Phone Help",
      shortDescription: "Setup, install apps, and usage guidance for smartphones.",
      detailedDescription: "From setup to app installation and usage guidance, we make your smartphone experience easy.",
      icon: FaMobile,
      suggestedPricing: "$60",
    },
    {
      id: 4,
      category: 'IT Support',
      title: "Printer Setup",
      shortDescription: "Install and configure printers and peripherals.",
      detailedDescription: "Efficient installation and troubleshooting of printers and peripherals.",
      icon: FaPrint,
      suggestedPricing: "$60",
    },
    {
      id: 5,
      category: 'IT Support',
      title: "Tech Support",
      shortDescription: "Personalized tech support for all your tech issues.",
      detailedDescription: "Fast and effective solutions for a wide range of technical issues.",
      icon: FaQuestionCircle,
      suggestedPricing: "$60",
    },
    {
      id: 6,
      category: 'IT Support',
      title: "Data Backup",
      shortDescription: "Implement backup solutions and recover lost data.",
      detailedDescription: "Robust backup solutions and expert data recovery services.",
      icon: FaRecycle,
      suggestedPricing: "$80",
    },
    {
      id: 7,
      category: 'IT Support',
      title: "Cybersecurity",
      shortDescription: "Enhance your online security.",
      detailedDescription: "Comprehensive security measures and safe browsing practices.",
      icon: FaShieldAlt,
      suggestedPricing: "$80",
    },
    {
      id: 8,
      category: 'Additional Services',
      title: "Tech Training",
      shortDescription: "Educational sessions on technology.",
      detailedDescription: "From basic skills to advanced concepts, tailored to your learning pace.",
      icon: FaUserGraduate,
      suggestedPricing: "$50",
    },
    {
      id: 9,
      category: 'IT Support',
      title: "Screen Repair",
      shortDescription: "Repair cracked or damaged screens.",
      detailedDescription: "Professional repair services for screens on various devices.",
      icon: FaTabletAlt,
      suggestedPricing: "$60",
    },
    {
      id: 10,
      category: 'Additional Services',
      title: "Device Advice",
      shortDescription: "Help with choosing the right tech devices.",
      detailedDescription: "Expert guidance on selecting tech devices that meet your needs.",
      icon: FaShoppingCart,
      suggestedPricing: "$60",
    },
    {
      id: 11,
      category: 'IT Support',
      title: "Device Repair",
      shortDescription: "Repair and upgrade computers and mobile devices.",
      detailedDescription: "Comprehensive repair and upgrade services for various devices.",
      icon: FaToolbox,
      suggestedPricing: "$75 per repair",
    },
    {
      id: 12,
      category: 'Additional Services',
      title: "Smart Home Setup",
      shortDescription: "Install and configure smart home devices.",
      detailedDescription: "Seamless setup of smart home technology for a modern living experience.",
      icon: FaHome,
      suggestedPricing: "$60 per setup",
    },
    {
      id: 13,
      category: 'IT Support',
      title: "Build a PC",
      shortDescription: "Custom build high-performance PCs.",
      detailedDescription: "Tailored PCs for gaming or professional use, from hardware selection to final build.",
      icon: FaScrewdriver,
      suggestedPricing: "Varies, starting at $200",
    },
    {
      id: 14,
      category: 'IT Support',
      title: "Software Help",
      shortDescription: "Troubleshoot and fix software issues.",
      detailedDescription: "Quick solutions for software errors, compatibility issues, and more.",
      icon: FaBug,
      suggestedPricing: "$60 per hour",
    },
    {
      id: 15,
      category: 'Additional Services',
      title: "Tech Advice",
      shortDescription: "Get expert tech advice.",
      detailedDescription: "Personalized insights and recommendations for tech upgrades and optimizations.",
      icon: FaHandsHelping,
      suggestedPricing: "$60 per session",
    },
    {
      id: 16,
      category: 'Additional Services',
      title: "Home Entertainment",
      shortDescription: "Setup home entertainment systems.",
      detailedDescription: "Optimize your home theater setup for the best audio-visual experience.",
      icon: FaTv,
      suggestedPricing: "$75 per setup",
    },
    {
      id: 17,
      category: 'IT Support',
      title: "Optimize Network",
      shortDescription: "Improve network performance.",
      detailedDescription: "Boost your network's speed and reliability for a better online experience.",
      icon: FaWifi,
      suggestedPricing: "$60 per session",
    },
    {
      id: 18,
      category: 'IT Support',
      title: "Remove Viruses",
      shortDescription: "Eliminate viruses and malware.",
      detailedDescription: "Swift identification and removal of harmful software.",
      icon: FaShieldAlt,
      suggestedPricing: "$100 per removal",
    },
    {
      id: 19,
      category: 'IT Support',
      title: "Remote Help",
      shortDescription: "Remote tech support for convenience.",
      detailedDescription: "Immediate remote assistance for tech issues.",
      icon: FaDesktop,
      suggestedPricing: "$60 per hour",
    },
    {
      id: 20,
      category: 'IT Support',
      title: "Upgrade Hardware",
      shortDescription: "Enhance device performance with upgrades.",
      detailedDescription: "Upgrade your device's components for better performance.",
      icon: FaScrewdriver,
      suggestedPricing: "Varies, starting at $80",
    },
    {
      id: 21,
      category: 'Web Development',
      title: "Custom Software",
      shortDescription: "Develop tailored software for your business.",
      detailedDescription: "Bespoke software solutions to streamline your business processes.",
      icon: FaCode,
      suggestedPricing: "Varies, contact for quote",
    },
    {
      id: 22,
      category: 'IT Support',
      title: "IT Consulting",
      shortDescription: "Expert IT advice and strategies.",
      detailedDescription: "Optimize your IT infrastructure with strategic insights and solutions.",
      icon: FaUserShield,
      suggestedPricing: "$60 per hour",
    },
    {
      id: 23,
      category: 'Web Development',
      title: "Cloud Services",
      shortDescription: "Integrate and manage cloud solutions.",
      detailedDescription: "Comprehensive cloud services for storage, applications, and more.",
      icon: FaCloud,
      suggestedPricing: "Varies, contact for quote",
    },
    {
      id: 24,
      category: 'Additional Services',
      title: "Recycle E-Waste",
      shortDescription: "Responsible disposal of electronic waste.",
      detailedDescription: "Eco-friendly recycling and disposal of your old electronics.",
      icon: FaRecycle,
      suggestedPricing: "Free or low-cost based on item",
    },
    {
      id: 25,
      category: 'Additional Services',
      title: "Gaming Setup",
      shortDescription: "Set up and optimize gaming systems.",
      detailedDescription: "Optimize your gaming rig or console for the best performance.",
      icon: FaGamepad,
      suggestedPricing: "$60 per setup",
    },
    {
      id: 26,
      category: 'IT Support',
      title: "Emergency Support",
      shortDescription: "Rapid response for urgent tech issues.",
      detailedDescription: "Immediate assistance for critical tech problems.",
      icon: FaAmbulance,
      suggestedPricing: "$65 per emergency call",
    },
    {
      id: 27,
      category: 'Web Development',
      title: "Web Design",
      shortDescription: "Create stunning and functional websites.",
      detailedDescription: "Beautiful, responsive websites tailored to your needs.",
      icon: FaLaptopCode,
      suggestedPricing: "Varies, contact for quote",
    },
    {
      id: 28,
      category: 'Web Development',
      title: "App Development",
      shortDescription: "Develop innovative mobile applications.",
      detailedDescription: "Engaging, user-friendly apps for iOS and Android.",
      icon: FaMobileAlt,
      suggestedPricing: "Varies, contact for quote",
    },
    {
      id: 29,
      category: 'Web Development',
      title: "Digital Marketing",
      shortDescription: "Enhance your online presence.",
      detailedDescription: "Strategic digital marketing to increase your visibility and reach.",
      icon: FaBullhorn,
      suggestedPricing: "$200 per month",
    },
    {
      id: 30,
      category: 'Web Development',
      title: "SEO Services",
      shortDescription: "Improve search engine rankings.",
      detailedDescription: "Effective SEO strategies to boost your online visibility.",
      icon: FaSearch,
      suggestedPricing: "$150 per month",
    }
  ];

  const filteredServices = initialServices.filter(service =>
    (service.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
     service.shortDescription.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedCategory === 'All' || service.category === selectedCategory)
  );

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
  };

  const handleCloseModal = () => {
    setSelectedFeature(null);
  };
  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}${window.location.pathname}`;

  return (
    <div className="features-container">
      <Helmet>
        <title>Services - Smeadit</title>
        <meta name="description" content="Smeadit offers a vast variety of services. Whether it's for general tech support, device repairs, or troubleshooting, get the support you need today." />
        <meta name="keywords" content="Services Smeadit, Smeadit Services, what does smeadit offer?" />
        <meta property="og:title" content="Services - Smeadit" />
        <meta property="og:description" content="Smeadit offers a vast variety of services. Whether it's for general tech support, device repairs, or troubleshooting, get the support you need today." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="../assets/Smeadit.png" />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" hreflang="en-au" href="https://smeadit.au/services" />
        <link rel="alternate" hreflang="en" href="https://smeadit.com/services" />
      </Helmet>
      <h1>Explore Our Services</h1>
      <div className="features-search-bar">
        <input 
          type="text" 
          placeholder="Search Services..."
          onChange={(e) => setSearchTerm(e.target.value)} 
        />
        <select onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
      </div>
      <div className="features-grid">
        {categories.filter(cat => cat !== 'All').map((category, index) => (
          <div key={index}>
            <h2 className="category-title">{category}</h2>
            <div className="category-grid">
              {filteredServices.filter(service => service.category === category).map((service, index) => (
                <div className="feature-card" key={index} onClick={() => handleFeatureClick(service)}>
                  <service.icon className="service-icon"/>
                  <h3 className="feature-title">{service.title}</h3>
                  <p className="feature-description">{service.shortDescription}</p>
                  <button className="feature-details-button">
                    Learn More
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {selectedFeature && <FeatureModal feature={selectedFeature} onClose={handleCloseModal} />}
      <p className="disclaimer">* Not all services are listed here. Contact us for more details.</p>
    </div>
  );
};

export default Features;
