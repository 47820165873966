import React from 'react';

const FeatureModal = ({ feature, onClose }) => {
  if (!feature) return null;

  return (
    <div className="feature-modal">
      <div className="modal-content">
        <h2>{feature.title}</h2>
        <p>{feature.detailedDescription}</p>
        <button onClick={onClose} className="modal-close-btn">Close</button>
      </div>
    </div>
  );
};

export default FeatureModal;
