import React, { useState, useEffect, useMemo } from 'react'; // Added useMemo here
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import '../../Css/SupportPage.css';


const supportMaterials = {
    videos: [
        { id: 1, title: "How to Download", url: "https://example.com/video1" },
        { id: 2, title: "How to Use the SmeadIT Website", url: "https://example.com/video2" },
    ],
    documents: [
        { id: 1, title: "Getting Started Guide", link: "https://example.com/doc1.pdf" },
        { id: 2, title: "FAQs", link: "https://example.com/faq.pdf" },
    ],
    faqs: [
        { id: 1, question: "How do I reset my password?", answer: "Follow these steps..." },
        { id: 2, question: "How can I contact customer support?", answer: "You can reach out to us via..." },
    ],
};

const categories = ['all', 'videos', 'documents', 'faqs'];


const SupportPage = () => {
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [isLargeText, setIsLargeText] = useState(false);
    const { transcript, resetTranscript, listening, stopListening } = useSpeechRecognition();

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        console.error("Browser doesn't support speech recognition.");
    }

    useEffect(() => {
        setSearchQuery(transcript);
    }, [transcript]);

    const isFuzzyMatch = (text, query) => {
        // Function to remove punctuation and spaces
        const normalize = str => str.toLowerCase().replace(/[\s\.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
        const textNormalized = normalize(text);
        const queryNormalized = normalize(query);

        if (!queryNormalized) return true; // Show all if no query
        let matchIndex = 0;
        for (let i = 0; i < queryNormalized.length; i++) {
            matchIndex = textNormalized.indexOf(queryNormalized[i], matchIndex);
            if (matchIndex === -1) return false;
            matchIndex++;
        }
        return true;
    };

    const filteredMaterials = useMemo(() => {
        const materials = selectedCategory === 'all' ? Object.values(supportMaterials).flat() : supportMaterials[selectedCategory];
        return materials.filter(material => isFuzzyMatch(material.title || material.question || '', searchQuery));
    }, [selectedCategory, searchQuery]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        resetTranscript(); // Clear the transcript when changing categories
        setSearchQuery(''); // Also clear the search query to reset the view
    };

    const toggleListening = () => {
        if (listening) {
            SpeechRecognition.stopListening(); // Directly use SpeechRecognition's stopListening
        } else {
            SpeechRecognition.startListening({ continuous: true });
        }
    };

    return (
        <div className={`support-page ${isLargeText ? 'large-text' : ''}`}>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1>Support Center</h1>
             <div className="search-controls">
                <input
                    type="text"
                    className="search-bar"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={listening ? stopListening : () => SpeechRecognition.startListening({ continuous: true })} className="voice-search-btn">
                    {listening ? 'Stop Listening' : 'Start Listening'}
                </button>
                <button onClick={() => { resetTranscript(); setSearchQuery(''); }} className="reset-btn">Reset</button>
            </div>
            <button onClick={() => setIsLargeText(!isLargeText)} className="text-size-toggle">Toggle Text Size</button>
            <div className="navigation">
                {categories.map(category => (
                    <button key={category} onClick={() => handleCategorySelect(category)} className={selectedCategory === category ? 'active' : ''}>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                    </button>
                ))}
            </div>
            <div className="content-area">
                {filteredMaterials.length > 0 ? filteredMaterials.map(material => (
                    <div key={material.id} className="support-item">
                        <h3>{material.title || material.question}</h3>
                        {material.url && <a href={material.url} target="_blank" rel="noopener noreferrer">Watch Video</a>}
                        {material.link && <a href={material.link} target="_blank" rel="noopener noreferrer">View Document</a>}
                        {material.answer && <p>{material.answer}</p>}
                    </div>
                )) : <p>No results found.</p>}
            </div>
        </div>
    );
};

export default SupportPage;
