import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Helmet } from 'react-helmet';

import '../../Css/QuotePage.css';
import { firestore } from '../firebase';
import { collection, serverTimestamp, addDoc } from 'firebase/firestore';
import AdvancedBookingSystem from './AdvancedBookingSystem';
import { Link } from 'react-router-dom';
const QuotePage = () => {
  const [showPopup, setShowPopup] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    selectedService: '',
    serviceType: '',
    address: '',
    postcode: '',
    additionalInfo: '',
    confirmed: false,
    bookingDate: '',
    bookingTime: ''
  });


  const services = {
    General: [
      "Computer Support",
      "Wi-Fi Assistance",
      "Phone Assistance",
      "General Tech Help",
      "Cable Management",
      "Email Support",
      "Device Setup",
    ],
    Advanced: [
      "Cybersecurity",
      "Data Backup & Recovery",
      "Software Help",
      "PC & Laptop Repair",
    ],
    Repairs: [
      "Screen Repairs",
      "Device Repairs",
      "Laptop Servicing",
    ],
    Setup: [
      "Smart Home Setup",
      "Entertainment System Setup",
      "Security System Setup",
      "Home Automation",
      "Smart Lighting",
    ],
    Development: [
      "Website Creation",
      "App Development",
    ],
    Creative: [
      "Video Editing",
    ],
    Other: [
      "Other Services (Specify in Details)"
    ]
  };
  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submissionTimestamp = new Date().toISOString();
  
    try {
      await emailjs.sendForm('service_e0pwn0m', 'template_pm2d5kn', e.target, 'YvWDp1JgzDcm2vsot');
  
      // Add quote request to Firestore
      await addDoc(collection(firestore, "quoteRequests"), {
        ...formData,
        submissionTimestamp,
      });
  
      // Add booking to Firestore
      await addDoc(collection(firestore, "bookings"), {
        date: formData.bookingDate,
        time: formData.bookingTime,
      });
  
      // Add notification for quote request
      await addDoc(collection(firestore, 'adminNotifications'), {
        eventType: 'New Quote Request',
        customerName: formData.name,
        customerEmail: formData.email,
        serviceRequested: formData.selectedService,
        timestamp: serverTimestamp(),
        additionalInfo: formData.additionalInfo,
        read: false,
      });
  
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      setFormData({ name: '', email: '', phone: '', selectedService: '', serviceType: '', address: '', postcode: '', additionalInfo: '', bookingDate: '', bookingTime: '' });
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const Popup = () => (
    <div className="popup">
      <div className="popup-inner">
        <p>Ticket submitted successfully!</p>
      </div>
    </div>
  );
  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}/booking`;

  return (
    <div className='containers'>
      <Helmet>
        <title>Book Tech Support - Smeadit</title>
        <meta name="description" content="Request a personalized tech support quote from Smeadit. Whether it's for cybersecurity, device repairs, or smart home setup, get the support you need today." />
        <meta name="keywords" content="Book Smeadit, Get a quote, SmeadIT quotes, How to book smeadit" />
        <meta property="og:title" content="Book Tech Support - Smeadit" />
        <meta property="og:description" content="Request a quote from Smeadit for our wide range of tech services tailored to meet your needs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="../assets/Smeadit.png" />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" hreflang="en-au" href="https://smeadit.au/booking" />
        <link rel="alternate" hreflang="en" href="https://smeadit.com/booking" />
      </Helmet>
        <br/>
        <br/>
        <br/>
       
        <br/>
    <div className="quote-form-container">
    <h2>Book Tech Support</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
        <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
        <input type="tel" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} />



  
        <div className="service-type-options">
        <label>
            <input 
            type="radio" 
            name="serviceType" 
            value="online" 
            onChange={handleChange}
            checked={formData.serviceType === 'online'} 
            /> Online Service
        </label>
        <label>
            <input 
            type="radio" 
            name="serviceType" 
            value="in-person" 
            onChange={handleChange}
            checked={formData.serviceType === 'in-person'} 
            /> In-Person
        </label>
        </div>

          {formData.serviceType === 'in-person' && (
            <>
              <input 
                type="text" 
                name="address" 
                placeholder="Address" 
                value={formData.address} 
                onChange={handleChange} 
              />
              <input 
                type="text" 
                name="postcode" 
                placeholder="Postcode" 
                value={formData.postcode} 
                onChange={handleChange} 
              />
            </>
          )}
      

          <select name="selectedService" value={formData.selectedService} onChange={handleChange} required>
            <option value="">Select a Service</option>
            {Object.entries(services).map(([category, services]) => (
              <optgroup label={category} key={category}>
                {services.map((service, index) => (
                  <option key={index} value={service}>{service}</option>
                ))}
              </optgroup>
            ))}
          </select>
          <textarea name="additionalInfo" placeholder="Additional Information" value={formData.additionalInfo} onChange={handleChange} />
         
          <AdvancedBookingSystem formData={formData} setFormData={setFormData} />

          <button type="submit">Submit Request</button>
          <br/>

      
        <br/>
          <p>
            Please be aware of the following terms and conditions:
            <ul>
              <center>
                <p>Bookings require at least 24 hours' notice.
                Your preferred time slot is subject to change based on availability.
                You will receive a confirmation email or call to confirm your appointment.</p>
                </center>
            </ul>
        </p>

      </form>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/> 
     {showPopup && <Popup />}
    </div>
  );
};

export default QuotePage;
