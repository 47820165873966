import React from 'react';
import { Link } from 'react-router-dom';
import { FaDesktop, FaCog, FaToolbox } from 'react-icons/fa'; // Import appropriate icons
import '../Css/Webdevelopment.css'; // Link to the CSS file
import { Helmet } from 'react-helmet';

import HeroImage from '../assets/background.png'; // Replace with actual image paths
import ClientLogo1 from '../assets/InpersonPhotos/TechHelp.jpg'; // Replace with actual image paths
import ClientLogo2 from '../assets/InpersonPhotos/Support.jpg';
import ClientLogo3 from '../assets/InpersonPhotos/Contact.jpg';

const WebDevelopment = () => {
  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}${window.location.pathname}`;

  return (
    <div className="web-dev-container">
      <Helmet>
        <title>Web Development Services | SmeadIT</title>
        <meta name="description" content="Quality web development services with flexible pricing to suit your company's or individual needs." />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" hreflang="en-au" href="https://smeadit.au/webdevelopment" />
        <link rel="alternate" hreflang="en" href="https://smeadit.com/webdevelopment" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Web Development Services | SmeadIT" />
        <meta property="og:description" content="Quality web development services with flexible pricing to suit your company's or individual needs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="../assets/Smeadit.png" />
      </Helmet>

      <section className="hero-section">
        <div className="hero-text-container">
          <h1>Web Development by SmeadIT</h1>
          <p>Quality service, flexible pricing, that suits your companies needs!</p>
          <div className="hero-buttons">
            <Link to="/contact"><button className="btn btn-primary">Get Started</button></Link>
            <Link to="/services"><button className="btn btn-secondary">Learn More</button></Link>
          </div>
        </div>
        <div className="hero-image-container">
          <img src={HeroImage} alt="Web Development" className="hero-image" />
        </div>
      </section>

      <section className="services-section">
      <h1 className='titleName'>Our Services!</h1>
      <br/>
      <br/>
      <br/>
      <div className="service-cards-container">
  <div className="service-card">
    <FaDesktop className="service-icon" />
    <h3>Website Design</h3>
    <p>We create beautiful, easy-to-navigate websites that reflect your brand's style and message. Our designs captivate visitors, making them more likely to stay and explore, increasing engagement.</p>
  </div>

  <div className="service-card">
    <FaCog className="service-icon" />
    <h3>Custom Web Applications</h3>
    <p>We develop tailor-made web applications that meet your specific needs. Our solutions simplify your online processes, make your site interactive, and support your business growth.</p>
  </div>

  <div className="service-card">
    <FaToolbox className="service-icon" />
    <h3>Ongoing Support</h3>
    <p>We offer continuous support to keep your website or app running smoothly. Our team ensures your online presence stays up-to-date, reliable, and ready to serve your customers at any time.</p>
  </div>
</div>
</section>

  
      <h1 className='titleName'>How it works!</h1>
  
  <br/>
  <br/>

  <section className="info-section">
  <div className="info-block">
 <div className="info-image-container">
   <img src={ClientLogo1} alt="Web Development Support" className="info-image" />
 </div>
 <div className="info-text-container">
   <h2>Need Web Development Help?</h2>
   <p>SmeadIT is here to assist you in creating a successful online presence. Simply contact us, and we'll discuss your needs to create the perfect solution for your business.</p>
   <Link to="/getstarted"><button className="service-btn">See Your Options</button></Link>
 </div>
</div>

<div className="info-block middle-block">
 <div className="info-text-container">
   <h2>Our Support Services</h2>
   <p>Once you reach out, we'll work together to understand your needs. You'll be able to manage your project online, approve designs, and access advanced features from our admin panel.</p>
   <Link to="/contact"><button className="service-btn">Request Help</button></Link>
 </div>
 <div class="info-image-container">
   <img src={ClientLogo2} alt="Online Management" className="info-image" />
 </div>
</div>

<div className="info-block">
 <div className="info-image-container">
   <img src={ClientLogo3} alt="More Information" className="info-image" />
 </div>
 <div className="info-text-container">
   <h2>Looking for More Information?</h2>
   <p>If you'd like more details on our services and support options, get in touch. We'll guide you through our offerings and help you make the best choice for your business.</p>
   <Link to="/about"><button className="service-btn">Learn More</button></Link>
 </div>
</div>
</section>



      {/* <section class="clients-section">
        <h2>Our Clients' Work</h2>
        <div className="clients-container">
          <div className="client">
            <img src={ClientLogo1} alt="Client 1 Logo" className="client-logo" />
            <p>Client 1 offers innovative solutions, leveraging our web development services to enhance their platform and engage customers effectively.</p>
            <Link to="/client1">Learn more about Client 1</Link>
          </div>

          <div class="client">
            <img src={ClientLogo2} alt="Client 2 Logo" className="client-logo" />
            <p>Client 2 provides comprehensive services, utilizing our web applications to streamline operations and provide a seamless user experience.</p>
            <Link to="/client2">Learn more about Client 2</Link>
          </div>

          <div class="client">
            <img src={ClientLogo3} alt="Client 3 Logo" className="client-logo" />
            <p>Client 3 has expanded its reach, leveraging our modern web designs to capture new audiences and strengthen its brand presence.</p>
            <Link to="/client3">Learn more about Client 3</Link>
          </div>
        </div>
      </section> */}

<section class="cta-section">
  <h2>Build Your Online Presence with SmeadIT</h2>
  
  <p>We're here to help you take your business online or enhance your current presence. Follow these steps for a seamless journey:</p>

  <div class="steps-container">
    <div class="step">
      <div class="step-number">1</div>
      <h3>Contact Us</h3>
      <p>Reach out to discuss your needs, goals, and ideas for your online presence.</p>
    </div>

    <div class="step">
      <div class="step-number">2</div>
      <h3>Plan & Design</h3>
      <p>We'll collaborate to create a plan, from design mockups to project timelines, tailored to your business needs.</p>
    </div>

    <div class="step">
      <div class="step-number">3</div>
      <h3>Development & Launch</h3>
      <p>We develop your project, testing and refining it for launch, ensuring everything works smoothly.</p>
    </div>

    <div class="step">
      <div class="step-number">4</div>
      <h3>Ongoing Support</h3>
      <p>Enjoy continuous support to keep your web solution updated, functional, and reliable for your users.</p>
    </div>
  </div>

  <Link to="/contact"><button className="cta-button">Contact Us</button></Link>
</section>


    </div>
  );
};

export default WebDevelopment;
