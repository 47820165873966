import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import MaintenancePage from './components/Modals/MaintenancePage';
import { database, firestore } from './components/firebase';
import { ref, onValue, set, push, onDisconnect, serverTimestamp, increment } from 'firebase/database';
import { collection, addDoc, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import './index.css';

const down = false;

const ViewerTracker = () => {
  useEffect(() => {
    const viewersRef = ref(database, 'viewers');
    const myConnectionsRef = push(viewersRef);
    const userInfoRef = ref(database, 'userInfo/' + myConnectionsRef.key);

    const updateUserInfo = async () => {
      const info = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        screenSize: `${window.screen.width}x${window.screen.height}`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        lastActive: serverTimestamp(),
        referrer: document.referrer,
        path: window.location.pathname,
        browserName: navigator.appName,
        browserVersion: navigator.appVersion,
        operatingSystem: navigator.platform
      };
      await set(userInfoRef, info);
    };

    const connectedRef = ref(database, '.info/connected');
    onValue(connectedRef, async (snap) => {
      if (snap.val() === true) {
        await set(myConnectionsRef, true);
        onDisconnect(myConnectionsRef).remove();
        onDisconnect(userInfoRef).remove();
        await updateUserInfo();
        await set(ref(database, 'pageViews'), increment(1));
        await set(ref(database, 'uniqueVisitors'), increment(1));
        localStorage.setItem('sessionStart', Date.now());
      }
    });

    const intervalId = setInterval(updateUserInfo, 60000); // Update every minute

    const logPageInteraction = async () => {
      await set(ref(database, 'pageInteractions'), increment(1));
    };

    document.addEventListener('click', logPageInteraction);
    document.addEventListener('scroll', logPageInteraction);

    return () => {
      clearInterval(intervalId);
      set(myConnectionsRef, null);
      set(userInfoRef, null);
      updateSessionDuration();
      localStorage.removeItem('sessionStart');
      document.removeEventListener('click', logPageInteraction);
      document.removeEventListener('scroll', logPageInteraction);
    };
  }, []);

  const updateSessionDuration = async () => {
    const sessionStart = localStorage.getItem('sessionStart');
    if (sessionStart) {
      const duration = (Date.now() - parseInt(sessionStart)) / 1000; // in seconds
      await addDoc(collection(firestore, 'sessionLogs'), {
        duration,
        timestamp: serverTimestamp()
      });

      const statsDoc = doc(firestore, 'statistics', 'sessionStats');
      const statsSnapshot = await getDoc(statsDoc);
      if (statsSnapshot.exists()) {
        const { totalDuration, sessionCount } = statsSnapshot.data();
        const newTotalDuration = totalDuration + duration;
        const newSessionCount = sessionCount + 1;
        await updateDoc(statsDoc, {
          totalDuration: newTotalDuration,
          sessionCount: newSessionCount,
          averageDuration: newTotalDuration / newSessionCount
        });
      } else {
        await setDoc(statsDoc, {
          totalDuration: duration,
          sessionCount: 1,
          averageDuration: duration
        });
      }
    }
  };

  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {down ? <MaintenancePage/> : (
      <>
        <App />
        <ViewerTracker />
      </>
    )}
  </React.StrictMode>
);
