import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc,serverTimestamp, updateDoc, setDoc, addDoc, collection } from "firebase/firestore"; 
import { Helmet } from 'react-helmet';


import { auth, firestore } from '../components/firebase';
import './Css/LoginSignup.css';

const googleProvider = new GoogleAuthProvider();

const LoginSignup = ({ setIsLoggedIn = () => {} }) => {
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [location, setLocation] = useState(''); // New state for location
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

 

    const toggleForm = () => {
        setIsLogin(!isLogin);
        setErrorMessage('');
    };
    const navigate = useNavigate();

    const updateLoginDates = async (user) => {
        const userRef = doc(firestore, "users", user.uid);
        const now = new Date();
        const updateData = { lastLoggedIn: now };
        
        const userDoc = await getDoc(userRef); // Use getDoc instead of userRef.get()
        if (!userDoc.exists()) {

            updateData.firstLoggedIn = now;
        }

        await updateDoc(userRef, updateData);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
      
          await updateDoc(doc(firestore, "users", user.uid), {
            lastLoggedIn: new Date(),
            isLoggedOut: false,
          });
      
          // Add login notification
          await addDoc(collection(firestore, 'adminNotifications'), {
            eventType: 'User Login',
            userId: user.uid,
            userEmail: user.email,
            timestamp: serverTimestamp(),
            read: false,
          });
      
          setIsLoggedIn(true);
          navigate('/dashboard');
        } catch (error) {
          setErrorMessage(error.message);
        }
      };
      
      const handleSignUp = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
          setErrorMessage('Passwords do not match');
          return;
        }
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
          const now = new Date();
      
          await setDoc(doc(firestore, "users", user.uid), {
            fullName,
            email,
            phoneNumber,
            location,
            isLoggedOut: false,
            accountCreated: now,
            firstLoggedIn: now,
            lastLoggedIn: now,
          });
      
          // Add sign-up notification
          await addDoc(collection(firestore, 'adminNotifications'), {
            eventType: 'New User Signup',
            userId: user.uid,
            userEmail: user.email,
            userFullName: fullName,
            timestamp: serverTimestamp(),
            read: false,
          });
      
          setIsLoggedIn(true);
          navigate('/dashboard');
        } catch (error) {
          setErrorMessage(error.message);
        }
      };
      
    


    const handleResetPassword = async () => {
        if (email) {
            try {
                await sendPasswordResetEmail(auth, email);
                setErrorMessage('Password reset email sent!');
            } catch (error) {
                setErrorMessage(error.message);
            }
        } else {
            setErrorMessage('Please enter your email address');
        }
    };
    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
    
            const userRef = doc(firestore, "users", user.uid);
            const userDoc = await getDoc(userRef);
    
            if (!userDoc.exists()) {
                // New Google sign-up
                await setDoc(userRef, {
                    fullName: user.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    accountCreated: new Date(),
                    firstLoggedIn: new Date(),
                    lastLoggedIn: new Date(),
                    
                    isLoggedIn: false // Set isLoggedIn to false
                });
    
                // Notify admin about new Google sign-up
                await addDoc(collection(firestore, 'adminNotifications'), {
                    eventType: 'New Google User Signup',
                    userId: user.uid,
                    userEmail: user.email,
                    userFullName: user.displayName,
                    timestamp: new Date()
                });
            } else {
                // Existing user logging in via Google
                await updateDoc(userRef, {
                    lastLoggedIn: new Date(),
                    isLoggedOut: false // Update isLoggedIn to false
                });
            }
    
            setIsLoggedIn(true);
            navigate('/dashboard');
        } catch (error) {
            console.error('Google Sign-In Error:', error);
            setErrorMessage(error.message);
        }
    };
    

    return (
        <div className="contact-wrapperer">
                        <Helmet>
                <title>Smeadit Login/Signup</title>
                <meta name="description" content="Login to Smeadit to access your tech support dashboard or sign up for personal or your business use." />
                <meta name="keywords" content="Smeadit login, Smeadit signup, tech support account, Smeadit account access, tech solutions sign in" />
                <meta property="og:title" content="Smeadit Login/Signup - Access Your Account" />
                <meta property="og:description" content="Login to Smeadit to access your tech support dashboard or sign up for personal or your business use." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="http://www.smeadit.com/login" />
   
                <link rel="canonical" href="http://www.smeadit.com/login" />
                
            </Helmet>
            <div id="login-signup-page"> {/* Add this ID */}
                <div className="login-signup-container">

                    {isLogin ? (
                        <div className="login-form">
                            <form onSubmit={handleLogin}>
                                <h1>Login</h1>
                                <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                <button type="submit">Login</button>
                                <button onClick={handleGoogleSignIn} className="google-signin-btn">Sign in with Google</button>
                                <p className="form-footer">

                                    Don't have an account? <span onClick={toggleForm}>Sign Up</span>
                                </p>
                                <p className="form-footer" onClick={handleResetPassword}>Forgot password?</p>
                            </form>
                        </div>
                    ) : (
                        <div className="signup-form">
                        <form onSubmit={handleSignUp}>
                            <h2>Sign Up</h2>
                            <input type="text" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
                            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <input type="text" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                            <input type="text" placeholder="Location" value={location} onChange={(e) => setLocation(e.target.value)} required />
                            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                           
                            <button type="submit">Sign Up</button>
                            <p className="form-footer">
                                Already have an account? <span onClick={toggleForm}>Login</span>
                            </p>
                        </form>
                    </div>
                    )}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>
            </div>
        </div>
    );
};

export default LoginSignup;
