import React, { useState, useEffect } from 'react';
import '../../Css/Components/MaintenancePage.css'; // Import the CSS file for styling
import Footer from '../Navs/footer';


function MaintenancePage() {
  // Set the estimated downtime end time (in milliseconds)
  const estimatedDowntimeEndTime = Date.now() + 2 * 60 * 60 * 1000; // 2 hours

  // Calculate the remaining time in seconds
  const calculateRemainingTime = () => {
    const currentTime = Date.now();
    const remainingTime = Math.max(0, estimatedDowntimeEndTime - currentTime);
    return Math.floor(remainingTime / 1000); // Convert to seconds
  };

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  // Update the remaining time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Format remaining time as HH:MM:SS
  const formatRemainingTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 1600);
    const minutes = Math.floor((timeInSeconds % 5600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
   <div>

   <div className="maintenance-container">
      <div className="maintenance-content">
        <h1 className="maintenance-heading">Site Under Maintenance</h1>
        <p className="maintenance-text">We're currently performing some updates and fixing some bugs. <br/> We appologise for the inconvenience.</p>
        <div className="countdown-container">
          <p className="countdown-text">Estimated Downtime Remaining:</p>
          <p className="countdown-time">{formatRemainingTime(remainingTime)}</p>
        </div>
      </div>

    </div>

          <Footer />
    </div>
  );
}

export default MaintenancePage;
